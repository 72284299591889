<template>
    <div class="box">
        <HeaderPage></HeaderPage>
        <div class="content">
            <h1>Terms</h1>
            <p>Please read these Terms of Service (the “Terms”) and our Privacy Policy carefully before using our Services (as defined below).  These Terms and our Privacy Policy govern the relationship between you and Subbi Games.</p>
            <p>(“Subbi Games”, “our”, “we” or “us”) and apply to your use of our Services.  The term “Services” includes, without limitation, mobile applications (each a “Game” and collectively “Games”), and any related services and content.</p>
            <p>Our Privacy Policy describes how we collect, store, use, share, and otherwise process your personal information when providing our Services to you, as well as your rights and choices concerning your information.  Please read our Privacy Policy carefully.</p>
            <p>If you do not agree to the Terms set forth below, or if you do not want us to collect, store, use, or share your information in the ways described in our Privacy Policy, you must not use our Services.</p>
            <p>Please note that, to use our Services, you need compatible hardware, software (latest version recommended and sometimes required), and internet access (fees may apply), among other technological features.  Our Services’ performance may be affected by these or related factors, each of which we do not control.</p>
            <h2>Agreement to Terms</h2>
            <p>By using our Services, you agree to be bound by these Terms. As set forth above, if you don’t agree to these Terms or our Privacy Policy, you must not use the Services.</p>
            <p>SECTION 18 OF THESE TERMS OF SERVICE CONTAINS AN INFORMAL DISPUTE RESOLUTION PROCESS, A BINDING AGREEMENT TO ARBITRATE, AND A CONSOLIDATED, CLASS ACTION, AND REPRESENTATIVE ACTION WAIVER, WHICH WAIVE YOUR RIGHT TO A COURT HEARING OR A TRIAL BEFORE A JUDGE OR JURY.</p>
            <h2>Changes to Terms or Services</h2>
            <p>We may modify the Terms at any time, at our sole discretion.  If we do so, we’ll let you know either by posting the modified Terms on the Site or through other communications via the Services. It’s important that you review the Terms carefully whenever we modify them, because if you continue to use the Services after we have posted modified Terms on the Site or via the Services, you are indicating to us that you agree to be bound by the modified Terms.  If you don’t agree to be bound by the modified Terms, then you must not continue to use the Site or the Services.</p>
            <p>Because our Services are evolving over time we may update, change, or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.  From time to time, we may make you update your software or hardware to continue to use our Services.  We may also perform these updates remotely without notifying you.</p>
            <p>We reserve the right to stop offering and/or supporting the Services or a particular Game or part of the Services at any time either permanently or temporarily, at which point your right to use the Services or any part of them will be automatically terminated or suspended.  If that happens, unless applicable law requires otherwise, we are not required to provide refunds, benefits, or other compensation to you in connection with discontinued elements of the Services or for Virtual Items (as defined below) previously earned or purchased.</p>
            <p>WE MAY, IN OUR SOLE DISCRETION, LIMIT, SUSPEND, TERMINATE, MODIFY, OR DELETE ACCOUNTS OR ACCESS TO THE SERVICES OR ANY PORTION OF THEM; PROHIBIT ACCESS TO OUR GAMES AND SITES, AND THEIR CONTENT, SERVICES, AND TOOLS; OR DELAY OR REMOVE HOSTED CONTENT, AND WE ARE UNDER NO OBLIGATION TO COMPENSATE YOU FOR ANY LOSSES OR RESULTS.</p>
            <h2>Limited License; Who May Use the Services</h2>
            <p>Limited License Grant.  Subject to your agreement and continuing compliance with these Terms, we grant you a non-exclusive, non-transferable, non-sublicensable, revocable limited license to access and use the Services for your own personal, non-commercial, and entertainment purposes.  You agree not to use the Services for any other purpose and further agree that we will have no liability to you for any damage or loss arising from unauthorized uses.</p>
            <p>Eligibility – You may use the Services only if you are 16 years or older and are not barred from using the Services under applicable law.</p>
            <p>If you are under the age of 18, or under the age of majority where you live, you represent that your legal guardian has reviewed and agreed to these Terms.</p>
            <p>You may not use our Services if you are not allowed to receive products, including services or software, from the United States, for example if you are in a country embargoed by the United States or if you are on the U.S. Treasury Department’s list of Specially Designated Nationals.</p>
            <p>Registration and Your Information – It’s important that you provide us with accurate, complete, and up-to-date information for your account and you agree to update such information, as needed, to keep it accurate, complete, and up to date.  If you don’t, we might have to suspend or terminate your account at your sole discretion, and without notice to you.  You agree that you won’t disclose your account password to anyone, and you’ll notify us immediately of any unauthorized use of your account.  You’re responsible for all activities that occur under your account, whether you know about them or not.</p>
            <h2>Intellectual Property Rights</h2>
            <p>Except for the limited license granted above, we and our licensors retain all right, title, and interest in and to the Services, including the technology and software used to provide the Services.  The Services are protected by copyright, trademark, trade dress, other worldwide intellectual property rights, and other applicable laws, rules, and regulations.  These Terms do not grant you or any other party any right, title, or interest in the Services or any content in the Services.</p>
            <p>In addition, you agree not to modify, create derivative works of, decompile, or otherwise attempt to extract source code from the Services.</p>
            <p>If you violate these Terms, or any of our other terms that apply to you, we may take action against you, including, among other things, terminating your account at our sole discretion, and without notice to you.  In addition, you may be breaking the law, including by violating our intellectual property rights. We and our licensors may also affirmatively protect our intellectual property rights in the event you violate these Terms, including by seeking injunctive relief against you.  ANY ATTEMPT BY YOU TO DISRUPT OR INTERFERE WITH THE SERVICES, INCLUDING, WITHOUT LIMITATION, UNDERMINING OR MANIPULATING THE LEGITIMATE OPERATION OF ANY GAME, VIOLATES THIS AGREEMENT AND MAY BE A BREACH OF CRIMINAL OR CIVIL LAWS.</p>
            <p>If you provide us with any suggestions for enhancement or feedback regarding the Services, or if you choose to participate in any Studio surveys, user research projects, or related activities, you agree that we will have a perpetual, transferable, sublicensable, royalty-free, irrevocable, worldwide license to use your suggestions and feedback or the results of any Studio surveys, user research projects, or related activities, including by incorporating your suggestions, feedback, or any results into the Services, without any obligation to compensate you.  To the extent permitted by applicable law, you waive any moral rights you may have in connection with your suggestions and feedback and/or the results of any Studio surveys, user research projects, or related activities.</p>
            <h2>User Content and Monitoring Use of Services</h2>
            <p>We may permit you or other users to submit, upload, publish, transmit, or otherwise make available to us materials, data, information, communications, pictures, and sounds using the Services (“User Content”).  Any User Content that you post, publish, or transmit will be considered non-proprietary and non-confidential.  You retain all of your ownership rights in your User Content, but you give us a perpetual, irrevocable, transferable, sublicensable, fully paid-up, royalty-free, non-exclusive, worldwide right and license to use, copy, reproduce, fix, adapt, modify, create derivative works from, manufacture, commercialize, publish, distribute, sell, license, sublicense, transfer, lease, transmit, publicly display, publicly perform, provide access to electronically, broadcast, communicate to the public by telecommunication, display, perform, enter into computer memory, and use and practice, in any way, your User Content, as well as any modified and derivative works thereof, in connection with the Services, including marketing and promotion of the Services, without notice to or consent from you, and without compensation to you or any other person or entity.  You further grant to Studio the unconditional, irrevocable right to use and exploit your name, likeness, and any other information or material submitted or transmitted to Studio in connection with any User Content, without any obligation by us or compensation to you.  Except as prohibited by law, you waive any rights of attribution and/or any moral rights you may have in your User Content.</p>
            <p>If you post, publish, transmit, or upload User Content to our Services, you represent, warrant, and affirm that it will be:</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;· Accurate;</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;· Not confidential or proprietary;</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;· Not in violation of law;</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;· Not in violation of contractual restrictions or other parties’ rights, and that you have permission to use the User Content from any other party whose personal or other information or intellectual property may be contained within the User Content;</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;· Not tortious, defamatory, libelous, obscene, threatening, harassing, abusive, violent, hateful, racist, or otherwise objectionable or inappropriate; and</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;· Free of viruses, adware, spyware, worms, or other malicious code.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;· As a user, you are personally and solely responsible for all information posted and/or sent, transmitted, or provided to others by you in connection with our Services, including, but not limited to, User Content posted in forums, blogs, and player chat features.</p>
            <p>We have no obligation to monitor the Services for inappropriate or illegal user Content or the conduct of other players, and we take no responsibility for such conduct.  We also are not responsible for information or materials provided by other players, and we do not approve User Content.  By using our Services, you understand that you may be exposed to conduct that you might find offensive or otherwise objectionable.  We do not endorse any User Content posted on the Services, nor do we guarantee its truthfulness or accuracy.  However, if someone is violating these Terms (including the Code of Conduct set forth below) or misusing the Services, please let us know by using any links provided in the Services or by contacting Customer Support.</p>
            <p>While we have no obligation to monitor the Services, we have the right, in our sole discretion, to monitor, record, or store your interactions with the Services or your communications with us or other players when you are using the Services.  For more information, see our Privacy Policy.</p>
            <p>We may also, at our sole discretion, choose to edit, refuse to post, or remove any User Content or any other materials that we deem in our sole discretion to be offensive or otherwise objectionable.  If we determine that your communications or User Content violate these Terms, we have the right, in our sole discretion and without notice, to disable your ability to post User Content or to communicate with other players, or we may terminate your access to the Services entirely, among other remedies available to us.</p>
            <h2>DMCA/Copyright Policy</h2>
            <p>We respect the intellectual property rights of others and ask that you do the same. We respond to notices of alleged copyright infringement that comply with the US Digital Millennium Copyright Act (“DMCA”), the E-Commerce Directive and associated legislation in the EU, and similar or equivalent other local laws that may apply. For more information, please go to our Copyright Page on our Site to review our notification guidelines. We reserve the right, in our sole discretion and without notice, to terminate any player’s access to the Services if we determine that the player may be a “repeat infringer.”</p>
            <h2>Payment Terms</h2>
            <h2>In-Game purchases and Virtual Items.</h2>
            <p>We provide a service in the form of access to games, virtual in-game items or currency, including virtual coins, cash, tokens, or points (collectively “Virtual Items”), and our other Services.  In the Services, you may use “real world” money to obtain a limited, personal, non-transferable, and revocable license and right to use Virtual Items and/or other goods or services solely for personal, non-commercial, and entertainment purposes.  As described below, you do not own Virtual Items that you obtained through our Services, regardless of whether you “earned” those Virtual Items or “purchased them.”  Your account and any related Virtual Items are owned by us and Virtual Items do not have “real world” value.</p>
            <p>When you purchase Virtual Items in our Games on third-party platforms such as Facebook, Apple, or Google, we are not a party to the transaction and your purchase will be governed by the third-party platform’s payment terms and conditions.  Please review the platform’s terms of service carefully for additional information.</p>
            <p>For Virtual Items, your order will represent an offer to us to obtain a limited license and right to use the relevant Virtual Item(s) that will be accepted by us when we accept payment.  At that point, the limited license begins.</p>
            <p>For orders to obtain a limited license and right to use Virtual Items, by clicking the purchase/order button on the purchase window or page you:</p>
            <p>Agree that we will supply the Virtual Items to you as soon as we have accepted your order; and</p>
            <p>If you reside in the European Union (the “EU”), you acknowledge that you will therefore no longer have the right to cancel under the EU’s Consumer Rights Directive (as implemented by the law of the country where you are located) once we start to supply the Virtual Item.</p>
            <p>You understand that while you may “earn,” “buy,” or “purchase” Virtual Items in our Services, you do not legally “own” the Virtual Items and the amounts of any Virtual Item do not refer to “real” money or any credit balance in actual real-world currency.  The amounts of any Virtual Items or any “virtual currency” balance shown in your account have no value outside of the game (e.g., in the real world), and instead only constitute a measurement of the extent of your limited license.  We may modify or eliminate Virtual Items at any time and at our sole discretion, with or without notice.</p>
            <p>You are not allowed to transfer Virtual Items outside of the Services (e.g., in the “real world”), for example by selling, gifting, or trading them.  We won’t recognize those transfers as legitimate.  In addition, you may not sublicense, trade, sell, or attempt to sell Virtual Items for “real” money, or exchange Virtual Items for value of any kind outside of a game.  Any such transfer or attempted transfer is prohibited and void, and we may terminate your Account because of it, among exercising other remedies available to us under these Terms or applicable law.</p>
            <p>ALL SALES ARE FINAL: YOU ACKNOWLEDGE THAT WE ARE NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY, OR WHETHER YOU MADE A PAYMENT THROUGH OUR SITE OR SERVICES OR ANOTHER PLATFORM SUCH AS APPLE, GOOGLE, FACEBOOK, OR ANY OTHER SITES OR PLATFORMS WHERE WE OFFER OUR SERVICES.</p>
            <p>PURCHASES TO ACQUIRE A LIMITED LICENSE AND RIGHT TO USE VIRTUAL ITEMS ARE NON-REFUNDABLE TO THE FULLEST EXTENT ALLOWED BY LAW.</p>
            <h2>Additional Payment Terms:</h2>
            <p>You agree to pay all fees and applicable taxes incurred by you or anyone using an account registered to you. We may revise the pricing for the Virtual Items it licenses to you through the Services at any time.</p>
            <h2>Subscriptions </h2>
            <p>Some of our Games may offer subscription-based Services.  If you purchase a subscription (or if you activate a trial subscription offered free of charge for a certain period of time that automatically converts to a paid subscription of if not cancelled prior to the expiration of the trial period), then by clicking the purchase button you are requesting that we begin supplying the subscription Services immediately and you are entering into a periodic subscription contract with us.  You are also authorizing a charge to you on a recurring basis of a periodic subscription fee at the rate quoted at the time of purchase.  By purchasing a subscription, we will automatically charge on a recurring basis the payment method associated with your account.  In addition, if you activate a trial subscription offered free of charge for a certain period of time and do not cancel the trial subscription prior to the expiration of that period, the trial subscription will automatically convert to a paid subscription and begin charging on a recurring basis the payment method associated with your account.  For subscription Services purchased in a Studio Game played on a platform such as Apple or Google, the applicable platform will charge you for the subscription fee and the platform’s payment terms will apply.  Please review the appropriate platform’s payment terms carefully for additional information.</p>
            <p>Subscription charges will be applied to the payment instrument or method you provide when you start your subscription (or to a different payment instrument or method if you change your account information). Please note that prices and charges, including the fees associated with any subscriptions or the prices for any Virtual Items, are subject to change at any time and in our sole discretion.  If we make a change to the periodic subscription rate in U.S. Dollars, we will let you know in advance.</p>
            <p>Your subscription will automatically renew each subscription period unless and until you terminate your subscription, or we terminate it.  You must cancel your subscription before it renews each billing period (or your trial subscription before expiration of the trial period), otherwise payment of the subscription fees for the next period will be taken automatically via your chosen payment method.  You are solely responsible for the timely payment of all fees and charges and in ensuring the accuracy of the payment information associated with your account.  If your payment does not process for any reason, your subscription may be automatically cancelled.</p>
            <p>For subscriptions purchased through a platform such as Apple or Google (or for trial subscriptions that automatically converts to a paid subscription of if not cancelled prior to the expiration of the trial period), you may cancel at any time directly through the platform, as follows: you can manage and cancel your subscription at any time in your “Account Settings” of your device. For iOS subscriptions, please see Apple’s support page available here.  For Google Play subscriptions, please see Google Play’s support page available here.  </p>
            <p>Except where required by applicable law, subscription payments are non-refundable and there WILL BE no refunds or credits for partially used subscription periods.</p>
            <h2>Links to Third Party Websites, Resources, or Advertising</h2>
            <p>Our Services may feature advertisements from us or other companies.  Our Privacy Policy explains what information we share with advertisers.  Please read it carefully.</p>
            <p>Sometimes we provide links in our games or on the Services to other third-party companies’ websites or resources.  We provide these links only as a convenience and are not responsible for the content, products, or services on or available from those websites or resources or links displayed on such websites.  We are not liable for any claim relating to any content, goods, and/or services of third parties, and you acknowledge sole responsibility for, and assume all risk arising from, your use of any third-party websites or resources.</p>
            <p>Please also note that we do not control linked third-party websites or resources and they may collect data or ask you to provide them with personal or other information, or they may automatically collect information from you.  When you use these third-party sites and services, the third-party company may (or may not) ask you for permission to access your information and content. We are not responsible for these other companies’ content, business practices, or privacy policies, or for how they collect, use, or share the information they get from you.</p>
            <h2>Termination</h2>
            <p>We have the right to terminate your access to, and use of, the Services, at our sole discretion, at any time and without notice to you.  Upon any termination, discontinuation, or cancellation of Services or your account, all provisions of these Terms which by their nature should survive will survive, including, without limitation, ownership provisions, warranty disclaimers, limitations of liability, and dispute resolution provisions.</p>
            <h2>Indemnity</h2>
            <p>If you violate the law or breach these Terms, and your violation or breach results in loss, damage, a claim, or liability against us, you agree to indemnify, defend, and hold us harmless from (which means you agree to compensate us for) that loss, damage, claim or liability, including our legal fees and expenses.  We may exercise exclusive control of the defense of any legal action subject to indemnification by you, at your expense.  You agree to cooperate in our defense of these actions.  We will use reasonable efforts to notify you of any claim for which you are obligated to indemnify us.</p>
            <h2>Warranty Disclaimers</h2>
            <p>We make no promises or guarantees that the Services or any content on them will always be available, uninterrupted, or error-free.  As set forth above, our Services’ performance may be affected by your hardware, software, and/or internet access, each of which we do not control.  In addition, we may suspend, withdraw, or restrict the availability of all or any part of our Services for business and operational reasons.</p>
            <p>USE OF THE SERVICES IS AT YOUR SOLE RISK.  THE SERVICES AND CONTENT ARE PROVIDED ON AN “AS IS'' BASIS, WITHOUT WARRANTY OF ANY KIND.  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE CLAIM, WE MAKE NO WARRANTIES, CONDITIONS, OR OTHER TERMS OF ANY KIND, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES.  WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, NON-INFRINGEMENT OR AVAILABILITY, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT THE SERVICES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS, OR RELIABILITY OF ANY CONTENT AVAILABLE IN THE SERVICES.</p>
            <p>If your state or country does not allow these disclaimers, they do not apply to you.  If your state or country requires a certain period for which a warranty applies, it will be either the shorter of thirty (30) days from your first use of the Services or the shortest period required by law.</p>
            <h2>Limitation of Liability</h2>
            <p>YOU ACKNOWLEDGE THAT NEITHER WE NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES (INCLUDING OUR CORPORATE PARENTS) WILL BE LIABLE:</p>
            <p>FOR ANY PERSONAL INJURY OR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING FOR LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE, SYSTEM FAILURE, OR THE COST OF SUBSTITUTE SERVICES, IN ANY WAY WHATSOEVER ARISING OUT OF THESE TERMS OR THE USE OF, OR INABILITY TO USE, THE SERVICES OR CONTENT, AND WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE; OR</p>
            <p>FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OTHER USERS OF THE SERVICES AND OPERATORS OF EXTERNAL WEBSITES OR RESOURCES.</p>
            <p>THE RISK OF USING THE SERVICES AND EXTERNAL WEBSITES OR RESOURCES RESTS ENTIRELY WITH YOU, AS DOES THE RISK OF INJURY FROM THE SERVICES OF EXTERNAL WEBSITES OR RESOURCES.</p>
            <p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, THE DISCLAIMERS OF LIABILITY IN THESE TERMS APPLY TO ALL DAMAGES OR INJURY CAUSED BY THE SERVICES, OR RELATED TO USE OF, OR INABILITY TO USE, THE SERVICES, UNDER ANY CAUSE OF ACTION IN ANY JURISDICTION, INCLUDING, WITHOUT LIMITATION, ACTIONS FOR BREACH OF WARRANTY, BREACH OF CONTRACT, OR TORT (INCLUDING NEGLIGENCE).  IF YOU LIVE IN A JURISDICTION THAT DOES NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
            <p>TO THE MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, THE TOTAL LIABILITY OF STUDIO AND ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES (INCLUDING OUR CORPORATE PARENTS) IS LIMITED TO THE TOTAL AMOUNT YOU HAVE PAID US IN THE SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM. IF YOU HAVE NOT PAID STUDIO ANY AMOUNT IN SUCH SIX (6) MONTH PERIOD, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH STUDIO IS TO STOP USING THE SERVICES AND TO CANCEL YOUR ACCOUNT.</p>
            <p>Some states or countries do not allow the exclusion of certain warranties or the limitations/exclusions of liability described above, which means these limitations/exclusions may not apply to you if you reside in one of those states or countries.  To the extent that we may not, as a matter of applicable law, disclaim any warranty or limit its liability as set forth herein, the scope of such warranty and the extent of our liability will be the minimum permitted under such applicable law.</p>
            <h2>Time Limitation on Claims</h2>
            <p>You agree that any claim you may have in any way arising out of or relating to your relationship with us, including your use of the Services, must be filed within one (1) year after the factual basis for the claim arose; otherwise, your claim will be permanently barred as untimely.</p>
            <h2>Governing Law and Venue</h2>
            <p>These Terms and our relationship will be governed by the laws of the State of California, except for its conflict of law principles.</p>
            <p>Further, you and we agree to the jurisdiction of the courts in Santa Clara County, California to resolve any dispute, claim, or controversy that relates to or arises in connection with the Services (and any non-contractual disputes/claims relating to or arising in connection with them).</p>
            <h2>Informal Dispute Resolution Process; Agreement to Arbitrate; Class Action and Representative Action Waiver</h2>
            <p>PLEASE READ THIS SECTION CAREFULLY.  IT AFFECTS YOUR RIGHTS AND INCLUDES WAIVER OF A TRIAL BY JURY IN A COURT AND OF THE ABILITY TO BRING ANY CLASS ACTIONS OR REPRESENTATIVE ACTIONS.</p>
            <h2>Overview; Notice of Claim and Required Informal Dispute Resolution Process</h2>
            <p>In the event of any controversy or claim arising out of or relating to these Terms, including any question regarding its existence, validity, termination, or breach thereof, or your use of the Services, the parties hereto shall consult and negotiate with each other and, recognizing their mutual interests, attempt to reach a satisfactory solution, through first contacting Customer Support and then through the Informal Dispute Resolution Process described below.  If these informal efforts do not resolve the concern or issue, as explained in more detail below, we each agree to resolve any dispute between us through binding arbitration or small claims court instead of in courts of general jurisdiction.</p>
            <p>If you have any concerns about, or experience any issue with, the Services, most user concerns or issues can be resolved by contacting our Customer Support team at subbigames@gmail.com. Before initiating the Informal Dispute Resolution Process (as described below) or any arbitration or court proceeding pursuant to this Section, you must first contact our Customer Support team to address your concerns or issues.  Most disputes can be resolved that way.</p>
            <h2>Severability</h2>
            <p>We apply to the maximum extent permitted by applicable law.  If any competent authority deems any portion of this Section illegal or unenforceable, such provision will be severed and the remainder of this Section will be given full force and effect.</p>
            <p>If any competent authority determines that applicable law precludes the arbitration of any claim, cause of action, or requested remedy, then that claim, cause of action, or requested remedy, and only that claim, cause of action, or requested remedy, will be severed from this Agreement to Arbitrate (as described below), and will be brought in a court of competent jurisdiction.  If a claim, cause of action, or requested remedy is severed pursuant to this paragraph, then you and we agree that the claims, causes of action, or requested remedies that are not subject to arbitration will be stayed until all arbitrable claims, causes of action, and requested remedies are resolved by the arbitrator.</p>
            <h2>Agreement to Arbitrate; Exceptions to Agreement to Arbitrate</h2>
            <p>If we cannot resolve our dispute through Customer Support or through an Informal Dispute Resolution Process (as described above), YOU AND WE AGREE TO RESOLVE ALL DISPUTES AND CLAIMS BETWEEN US IN INDIVIDUAL BINDING ARBITRATION, INCLUDING CLAIMS CONCERNING ANY ASPECT OF THE RELATIONSHIP BETWEEN US, YOUR DECISION TO DOWNLOAD THE GAME, YOUR USE OF THE GAME, ANY USER CONTENT, OR YOUR ACCOUNT.</p>
            <p>This Agreement to Arbitrate is intended to be interpreted broadly, and it applies to all disputes or claims of any kind under any theory (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory) that you may have or assert against us, including disputes or claims that you assert or that arise after you stop using the Services or delete your account for the Game, stop using the Game, or delete the Game application.  This Agreement to Arbitrate also applies to any claims that are currently the subject of a purported class action litigation in which you are not a member of a certified class.</p>
            <p>You and we agree that the arbitrator shall have the exclusive authority to resolve any dispute relating to the interpretation, applicability, or enforceability of the terms or formation of this contract, including whether any dispute between us is subject to this Agreement to Arbitrate (i.e., the arbitrator will decide the arbitrability of any dispute) and whether all or any part of these terms are void or voidable.</p>
            <p>Please note that an arbitration is a proceeding before a neutral arbitrator, instead of before a judge or jury.  Arbitration is less formal than a lawsuit in court and provides more limited discovery.  It follows different rules than court proceedings and is subject to very limited review by courts.  The arbitrator will issue a written decision and provide a statement of reasons if requested by either party.  YOU UNDERSTAND THAT YOU AND WE ARE GIVING UP THE RIGHT TO SUE IN COURT AND THE RIGHT TO HAVE A TRIAL BEFORE A JUDGE OR JURY.</p>
            <h2>Exceptions to Agreement to Arbitrate</h2>
            <p>This Section does not apply to the following types of claims or disputes, which you or we may bring in court in accordance with above:</p>
            <p>(1) claims of infringement or other misuse of intellectual property rights, including such claims seeking injunctive relief; and</p>
            <p>(2) claims for preliminary injunctive relief for violations of these Terms.</p>
            <p>This Section does not prevent you from bringing your dispute to the attention of any federal, state, or local government agencies that can, if the law allows, seek relief from us for you.  In addition, any of us can bring a claim in small claims court either in Santa Clara County, California, or the county where you live, or some other place we both agree on, if such claims meet the requirements to be brought in that small claims court.  If either party files a claim in arbitration that could have been brought in small claims court, the other party may elect to resolve the claim through small claims court (rather than through arbitration) by providing notice to the other party before appointment of an arbitrator; upon receipt of such notice, the arbitration provider shall administratively close the case before assessing any fees, and the party bringing the claim must proceed in small claims court in lieu of arbitration.</p>
            <h2>For non-US Users</h2>
            <p>In the event of any controversy or claim arising out of or relating to these Terms, including any question regarding its existence, validity, termination, or breach thereof, the parties shall consult and negotiate with each other in good faith and, recognizing their mutual interests, attempt to reach a satisfactory solution. If they do not reach settlement within a period of sixty (60) days, then, upon notice by any party to the other(s), any unresolved controversy or claim shall be settled by arbitration administered by the International Centre for Dispute Resolution (“ICDR”) (www.icdr.org) in accordance with the provisions of its International Arbitration Rules. The place of arbitration shall be in London, England, and the number of arbitrators shall be one. The language to be used in the arbitral proceedings shall be English.</p>
            <p>Except as may be required by law, neither a party nor its representatives may disclose the existence, content, or results of any arbitration hereunder without the prior written consent of Studio. The European Union also operates an online dispute resolution platform which can be found at www.ec.europa.eu/consumers/odr.</p>
            <h2>Class Action and Representative Action Waiver</h2>
            <p>For disputes arising between us and you, or any other user, you and we agree that we can only bring a claim against each other on an individual basis. NEITHER YOU NOR WE CAN BRING A CLAIM AS A PLAINTIFF OR CLASS MEMBER IN A CLASS ACTION, CLASS-WIDE ARBITRATION, CONSOLIDATED ACTION, OR REPRESENTATIVE ACTION.  THE ARBITRATOR CANNOT COMBINE MORE THAN ONE PERSON’S CLAIM INTO A SINGLE CASE, AND CANNOT PRESIDE OVER ANY CONSOLIDATED, CLASS, OR REPRESENTATIVE ARBITRATION PROCEEDING, UNLESS WE BOTH AGREE OTHERWISE IN WRITING.  NONETHELESS, IF ANY PORTION OF THIS CLASS ACTION OR REPRESENTATIVE ACTION WAIVER IS DEEMED UNENFORCEABLE OR INVALID, THE ARBITRATOR SHALL HAVE AUTHORITY TO ISSUE ANY AND ALL REMEDIES AUTHORIZED BY LAW.</p>
            <p>YOU UNDERSTAND THAT, ABSENT THIS AGREEMENT TO ARBITRATE, YOU WOULD HAVE HAD A RIGHT TO LITIGATE THROUGH A COURT, TO HAVE A JUDGE OR JURY DECIDE YOUR CASE, AND TO BE PARTY TO A CONSOLIDATED, CLASS, OR REPRESENTATIVE ACTION.  HOWEVER, YOU UNDERSTAND AND CHOOSE TO HAVE ANY CLAIMS DECIDED INDIVIDUALLY AND ONLY THROUGH ARBITRATION.</p>
            <h2>Contact Information</h2>
            <p>If you have any questions about these Terms or the Services, please contact us at subbigames@gmail.com.</p>
        </div>
        <FooterPage></FooterPage>
    </div>
</template>

<script>
import HeaderPage from "@/components/headerPage/index.vue"
import FooterPage from "@/components/footerPage/index.vue"
export default {
    name: "RegulationPage",
    components: {
        HeaderPage,
        FooterPage
    },
}
</script>

<style lang="scss" scoped>
.box{
  background-color: #fff;
  font-weight: bolder;
}
.content{
    width: 80%;
    margin: 150px auto;
    h1{
      margin-bottom: 40px;
    }
    h2{
      margin-bottom: 20px;
    }
    p{
      line-height: 25px;
      margin-bottom: 20px;
    }
}
</style>