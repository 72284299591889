<template>
    <div>
        <HeaderPage></HeaderPage>
        <div class="container">
            <div class="topBox" :style="backgroundStyle">
                <div class="topBox_text">
                    <h1 class="text_color">Made to Be Played</h1>
                    <div class="text_color ">The Very Best in Mobile Gaming</div>
                    <div>
                        <img style="width: 135px; height: 45px; margin-right: 5px;" src="../../assets/images/Appstore.png" alt="">
                        <img style="width: 152px; height: 45px;" src="../../assets/images/Google Play.png" alt="">
                    </div>
                </div>
                <div class="topGameBox">
                    <div class="gameItem1">
                        <p><strong>Bubble Fever</strong></p>
                        <p>Casual-Free</p>
                    </div>
                    <div class="gameItem2">
                        <p><strong>Robotrix</strong></p>
                        <p>RPG-Free</p>
                    </div>
                    <div class="gameItem3">
                        <p><strong>Treasure</strong></p>
                        <p>Adventure-Free</p>
                    </div>
                    <div class="gameItem4" @click="toScroll('game')">
                        <p>Explore Our</p>
                        <p>Games</p>
                        <p>↓</p>
                    </div>
                </div>
            </div>
            <div class="cenAbout">
                <h2>About Subbi Games</h2>
                <p class="p1 text_color"><strong>Your Source for Mobile <br> Entertainment</strong></p>
                <p class="p2 text_color">We are an ambitious company determined to move into the leisure sector and strive to become a leader in the field. We welcome people from all walks of life who are interested in casual games to join us and let's create the future of casual games together.</p>
                <el-button class="elBtn" type="danger" @click="toPage('/careers')">Learn More</el-button>
                
                <img src="../../assets/images/about.png" alt="">
                <img class="about_left" src="../../assets/images/about_left.png" alt="">
            </div>
            <div class="gameBox">
                <div class="gameBoxTit" id="game">
                    <p class="p1 text_color"><strong>Our Games</strong></p>
                    <p class="p2 text_color">Bubble Dragon, our new excellent casual game category! After synthesizing a variety of bubble dragon games on the market, we decided to go back to the basics, using the simplest gameplay to bring users the ultimate fun, "Bubble Fever", our sincere work, we hope you will enjoy this game.</p>
                </div>
                <div class="gameBoxPhone">
                    <div class="gameContact">
                        <el-carousel style="height: 100% !important;" indicator-position="none">
                            <el-carousel-item v-for="(item,index) in gameData" :key="index">
                                <div class="gameItem" style="width: 100%; height: 100%;">
                                    <div class="gameItem_top">
                                        <img :src="item.gameIcon" alt="">
                                        <div>
                                            <p><strong>{{ item.gameTit }}</strong></p>
                                            <p>{{ item.gameType }}</p>
                                        </div>
                                    </div>
                                    <div class="gameItem_cen">
                                        <p>{{ item.gameText }}</p>
                                    </div>
                                    <div class="gameItem_bot">
                                        <img style="width: 137px; height: 40px;" src="../../assets/images/Appstore.png" alt="">
                                        <img style="width: 137px; height: 40px;" src="../../assets/images/Google Play.png" alt="">
                                    </div>
                                    
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
            <div class="contactBox">
                <div class="contactBox1"  id="contact" :style="contactBackgroundStyle1">
                    <div class="contactBox1_left">
                        <h2>Join Our Team</h2>
                        <p><strong>It takes the world's best talent <br> to change the game.</strong></p>
                        <el-button type="danger" style="width: 150px;height: 60px; font-size: 16px; background-color: #FCFF72; color: #272243;">Explore Careers</el-button>
                    </div>
                    <div class="contactBox1_right">
                        <img src="../../assets/images/contact1_right.png" alt="">
                    </div>
                </div>
                <div class="contactBox2" :style="contactBackgroundStyle2">
                    <div class="contactBox2_left">
                        <h3>Get in Touch</h3>
                        <p><strong>Please fill out our form and give us your opinion!</strong></p>
                        <el-form :model="Form" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                            <el-form-item label="First Name" prop="x">
                                <el-input v-model="Form.x" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="Last Name" prop="m">
                                <el-input v-model="Form.m" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="Email" prop="Email">
                                <el-input v-model.number="Form.Email"></el-input>
                            </el-form-item>
                            <el-form-item label="subject" prop="subject">
                                <el-input v-model.number="Form.subject"></el-input>
                            </el-form-item>
                            <el-form-item label="message" prop="message">
                                <el-input v-model.number="Form.message"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" style="background-color: #272243; border: none; width: 100%; margin: 0 auto;"
                                @click="submitForm('ruleForm')">submit</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="contactBox2_right">
                        <p><strong>Subscribe to Our Newsletter</strong></p>
                        <el-form :model="Form" status-icon :rules="rules" ref="ruleForm2" label-width="100px" class="demo-ruleForm" style="display: flex;">
                            <el-form-item label="Email" prop="Email" label-color="#FFF">
                                <el-input v-model="Form.Email" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" style="background-color: #FCFF72; border: none; color: #272243; margin-left:50%;"
                                @click="submitForm('ruleForm2')">Join</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
        <FooterPage></FooterPage>
    </div>
</template>

<script>
import HeaderPage from "@/components/headerPage/index.vue"
import FooterPage from "@/components/footerPage/index.vue"
export default {
    name: "HomePage",
    components: {
        HeaderPage,
        FooterPage
    },
    data(){
        return{
            Form:{},
            backgroundPositionX: -750,
            backgroundPositionY1:0,
            backgroundPositionY2:0,
            gameData:[
                {gameTit:"BUBBLE FEVER",gameIcon:require("@/assets/images/gameIcon1.png"),gameType:"Casual - Free",gameText:"Real multiplayer battle game, compete to see who is more skilled in bubble. Have fun competing in randomized Bubble formations!"},
                {gameTit:"Robotrix",gameIcon:require("@/assets/images/gameIcon2.png"),gameType:"RPG - Free",gameText:"Robotrix is an open-world game where players can freely explore a vast futuristic city. "},
                {gameTit:"Treasure Box",gameIcon:require("@/assets/images/gameIcon3.png"),gameType:"Adventure - Free",gameText:"Treasure Box is a classic adventure game where players take on the role of a treasure hunter, navigating various dangerous environments to find lost treasures."},
            ],
            rules: {
                x: [{ required: true, message: 'Last name is a required field', trigger: 'blur' },],
                m: [{ required: true, message: 'First name is a required field', trigger: 'blur' },],
                Email: [{ required: true, message: 'Email is a required field', trigger: 'blur' },],
            }
        }
    },
    computed:{
        backgroundStyle() {
            return {
                backgroundPositionX: this.backgroundPositionX + 'px',
                backgroundRepeat: 'no-repeat',
            };
        },
        contactBackgroundStyle1() {
            return {
                backgroundPositionY: this.backgroundPositionY1 + 'px',
            };
        },
        contactBackgroundStyle2() {
            return {
                backgroundPositionY: this.backgroundPositionY2 + 'px',
            };
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        if (window.innerWidth < 768) {
            this.backgroundPositionX = -180;
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods:{
        toPage(key) {// 用于页面跳转
            this.$router.push({ 'path': key }).catch(() => { })
            this.drawer = false;
        },
        handleScroll(){
            this.backgroundPositionX =  (-1 * window.scrollY / 3) -(window.innerWidth >= 768?750:180); // 根据滚动距离调整，这里是3倍的滚动速度
            this.backgroundPositionY1 = (-1 * window.scrollY / 2) + 1700;
            this.backgroundPositionY2 = (-1 * window.scrollY / 2.5) + 1400;
        },
        toScroll(id){
            setTimeout(()=>{
                const element = document.getElementById(id);
                if (element) {
                    window.scrollTo({
                        top: element.offsetTop,
                        behavior: 'smooth'
                    });
                    this.drawer = false;
            }
            },1)
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let mailto = 'mailto:subbigames@gmail.com?body=' + this.Form;
                    window.location.href = mailto;
                } else {
                    return false;
                }
            });
        },
        
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container{
    height: 100% !important;
    font-size: 24px !important;
}
::v-deep .el-form:last-child{
    .el-form-item:last-child{
        .el-form-item__content:last-child{
            margin-left: 0 !important;
        }
    }
}
.container{
    margin-top: 0px;
    .topBox{
        width: 100%;
        height: 800px;
        background-Image: url("@/assets/images/home_top_bg.jpg");
        background-position: center;
        background-position-x: -750px;
        background-repeat: no-repeat;
        margin-bottom: 40%;

        .topBox_text{
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            font-size: 40px;
            padding-left: 50px;
            :nth-child(2){
                margin: 20px 0;
            }
            :nth-child(3){
                display: flex;
                align-items: center;
            }
        }
        .topGameBox{
            width: 90%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            height: 70%;
            position: relative;
            top: -10%;
            div{
                width: 20%;
                height: 100%;
                border-radius: 20px;
                background-repeat: no-repeat;
                position: relative;
                font-size: 18px;
                p{
                    color: #fff;
                    padding-left: 20px;
                    margin-bottom: 10px;
                }
                margin: 20px 0;
            }
            div:after {
                content: ''; /* 设置伪元素内容为空 */
                position: absolute; /* 设置绝对定位 */
                border-radius: 20px;
                top: 0; /* 从顶部开始 */
                left: 0; /* 从左边开始 */
                right: 0; /* 从右边结束 */
                bottom: 0; /* 从底部结束 */
                background: linear-gradient(to top, rgba(255, 0, 0, 0.5), rgba(255, 0, 0, 0)); /* 渐变背景色 */
                transition: opacity 0.5s;
            }
            div:hover:after{
                opacity: 0;
            }
            .gameItem1{
                background-image: url("../../assets/images/home页2.jpg");
                background-size: 1000px;
                background-position: center;
                transition: background-size 0.5s,font-size 0.5s;
                :nth-child(1){
                    margin-top: 125%;
                }
                
            }
            .gameItem1:hover{
                background-size: 1100px;
                font-size: 0;
            }
            .gameItem2{
                background-image: url("../../assets/images/game2.png");
                background-size: 1000px;
                background-position: center;
                transition: background-size 0.5s,font-size 0.5s;
                :nth-child(1){
                    margin-top: 125%;
                }
            }
            .gameItem2:hover{
                background-size: 1100px;
                font-size: 0;
            }
            .gameItem3{
                background-image: url("../../assets/images/game3.png");
                background-size: 1000px;
                background-position: center;
                transition: background-size 0.5s,font-size 0.5s;
                :nth-child(1){
                    margin-top: 125%;
                }
            }
            .gameItem3:hover{
                background-size: 1100px;
                font-size: 0;
            }
            .gameItem4{
                background-color: #fbfc71;
                transition: font-size 0.5s;
                text-align: center;
                font-size: 40px;
                p{color: #272443;}
                :nth-child(1){
                    margin-top: 80%;
                }
            }
            .gameItem4:hover{
                font-size: 50px;
            }
        }
    }
    .cenAbout{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 1000px;
        position: relative;
        h2{
            font-size: 21px;
        }
        .p1{
            font-size: 50px;
        }
        .p2{
            font-size: 18px;
            width: 50%;
        }
        .elBtn{
            width: 142px;
            height: 60px;
            background-color: #DA392B;
            font-size: 16px;
        }
        img{
            border-radius: 15px;
        }
        .about_left{
            position: relative;
            left: -20%;
            bottom: 17%;
        }
    }
    .gameBox{
        margin-bottom: 10%;
        .gameBoxTit{
            text-align: center;
            .p1{
            font-size: 50px;
            }
            .p2{
                font-size: 18px;
                width: 70%;
                margin: 30px auto;
            }
        }
        .gameBoxPhone{
            width: 100%;
            height: 1230px;
            background-image: url("../../assets/images/phone.png");
            background-size: 2500px;
            background-position: center;
            background-repeat: no-repeat;
            padding-top: 100px;
            .gameContact{
                width: 460px;
                height: 700px;
                // background-color: #272443;
                margin: 0 auto;
                .gameItem{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .gameItem_top{
                        width: 80%;
                        display: flex;
                        justify-content: space-evenly;
                        img{
                            border-radius: 10px;
                        }
                    }
                    .gameItem_cen{
                        width: 60%;
                        margin: 40px auto;
                    }
                    .gameItem_bot{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: space-evenly;
                    }
                }
            }
        }
    }
    .contactBox{
        .contactBox1{
            width: 100%;
            height: 750px;
            background-image: url("../../assets/images/contactBg1.png");
            background-repeat: no-repeat;
            background-position-y: 0;
            background-size: 2200px;
            background-position-x: center;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            div{
                width: 50%;
                min-width: 320px;
            }
            .contactBox1_left{
                height: 100%;
                display: flex;
                flex-direction: column;
                padding: 50px 50px;
                color: #fff;
                h2{font-size: 21px;}
                p{font-size: 50px;margin: 50px 0;}
            }
            .contactBox1_right{
                height: 100%;
                position: relative;
                img{
                    position: absolute;
                    bottom: 0px;
                    right: 10%;
                }
            }
        }
        .contactBox2{
            width: 100%;
            height: 600px;
            background-image: url("../../assets/images/contactBg2.png");
            background-repeat: no-repeat;
            background-position-y: 0;
            background-position-x: center;
            background-size: 2500px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            .div{
                width: 50%;
                position: relative;
            }
            .contactBox2_left{
                background-color: #fff;
                padding: 20px;
                border-radius: 20px;
                h3{font-size: 34px;}
                p{font-size: 18px;margin: 20px 0;}
                position: relative;
                top: -50%;
            }
            .contactBox2_right{
                background-color: #DA392B;
                color: #fff;
                p{font-size: 21px;margin: 20px auto;width: 80%;}
                width: 390px;
                height: 210px;
                border-radius: 20px;
                padding-right: 5%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }
        }
    }
}
@media (max-width: 1300px) {
    .container{
        .topBox{
            margin-bottom: 50%;
            .topGameBox{
                height: 50%;
                .gameItem4{
                    :nth-child(1){
                        margin-top: 50%;
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
::v-deep .el-carousel__container{
    font-size: 16px !important;
}
    .container{
        .topBox{
            background-position: center;
            background-size: 730px;
            height: 300px;
            margin-bottom: 1700px;
            .topBox_text{
                font-size: 16px;
                h1{font-size: 40px;}
                padding-left: 10px;
                div>image{
                    width: 117px;
                    height: 35px;
                }
            }
            .topGameBox{
                top: 0 !important;
                div{
                    width: 280px;
                    height: 374px;
                    :nth-child(1){
                        margin-top: 100% !important;
                    }
                    p{
                        padding-left: 5px;
                    }
                }
                .gameItem4{
                    padding-left: 0;
                    font-size: 18px;
                    :nth-child(1){
                        margin-top: 70% !important;
                    }
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .gameItem4:hover{
                    font-size: 20px;
                }
            }
        }
        .cenAbout{
            height: 750px;
            h2{ font-size: 18px; }
            .p1{ font-size: 26px; }
            .p2{
                font-size: 16px;
                width: 280px;
            }
            .elBtn{
                width: 142px;
                height: 60px;
                background-color: #DA392B;
                font-size: 16px;
            }
            img{
                width: 80%;
                height: auto;
            }
            .about_left{
                width: 160px;
                height: auto;
                left: 0%;
                bottom: 5%;
            }
        }
        .gameBox{
            top: 0;
            .gameBoxTit{
                .p1{ font-size: 26px; }
                .p2{ font-size: 16px; }
            }
            .gameBoxPhone{
                height: 750px;
                background-size: 1500px;
                .gameContact{
                    width: 65%;
                    height: 65%;
                }
            }
        }
        .contactBox{
            div{
                background-size: 1200px !important;
                background-position-y: 0 !important;
            }
            .contactBox1{
                height: 440px;
                div{height: 220px !important;}
                .contactBox1_left{
                    h2{font-size: 18px;}
                    p{font-size: 26px;}
                    position: relative;
                    z-index: 9;
                }
                .contactBox1_right{
                    z-index: 8;
                    height: 230px;
                    img{
                        width: 90% !important;
                        left: 50% !important;
                        transform: translate(-50%) !important;
                        bottom: 0px !important;
                    }
                }
            }
            .contactBox2{
                height: 1200px !important;
                .contactBox2_left{
                    top: 10%;
                    padding: 10px;
                    width: 80%;
                    height: 500px !important;
                    h3{font-size: 23px;}
                    p{font-size: 17px;}
                }
                .contactBox2_right{
                    width: 80%;
                }
            }
        }
    }
}
</style>