<template>
  <div class="container" style="background-color: #fff;">
    <HeaderPage></HeaderPage>
    <div class="detailBox">
      <div class="detailBox_top" :style="contactBackgroundStyle">
        <div class="gameItem">
          <div class="gameItem_top">
            <img src="../../assets/images/gameIcon3.png" alt="">
            <div>
              <p class="p1"><strong>Treasure Box</strong></p>
              <p class="p2">Adventure - Free</p>
            </div>
          </div>
          <div class="gameItem_cen">
            <p><strong>A Free-to-Play Adventure Game That Seriously Packs a Punch</strong></p>
          </div>
          <div class="gameItem_bot">
            <p>Treasure Box is a classic adventure game where players take on the role of a treasure hunter, navigating various dangerous environments to find lost treasures. The game is filled with intricate puzzles and hidden secrets waiting to be discovered.</p>
          </div>
        </div>
      </div>
      <div class="detailBox_cen">
        <h2><strong>Screenshots</strong></h2>
        <div class="screenshotsBox">
          <div class="screenshots1"></div>
          <div class="screenshots2"></div>
        </div>
      </div>
      <div class="detailBox_down" :style="contactBackgroundStyle2">
        <div class="detailBox_down2"></div>
        <div class="textBox">
          <p class="p1"><strong>Set Sail On Adventures to Win Tokens and Unlock Rare Items</strong></p>
          <p class="p2"><strong>In Treasure Box, players must use their wit and courage to overcome numerous obstacles, unlocking hidden stories and treasures along the way. With stunning visuals and an engaging plot, each adventure is packed with anticipation and excitement.</strong></p>
        </div>
      </div>
    </div>
    <FooterPage></FooterPage>
  </div>
</template>

<script>
import HeaderPage from "@/components/headerPage/index.vue"
import FooterPage from "@/components/footerPage/index.vue"
export default {
  name: "GameDetail3",
  components: {
    HeaderPage,
    FooterPage
  },
  data(){
        return{
            backgroundPositionY:-310,
            backgroundPositionY2:-800,
        }
    },
    mounted() {
      if (window.innerWidth <= 768) {
        this.backgroundPositionY = -100
        this.backgroundPositionY = -800
      }
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    computed:{
        contactBackgroundStyle() {
            return {
                backgroundPositionY: this.backgroundPositionY + 'px',
            };
        },
        contactBackgroundStyle2() {
            return {
                backgroundPositionY: this.backgroundPositionY2 + 'px',
            };
        },
    },
    methods:{
        handleScroll(){
            this.backgroundPositionY = (window.scrollY / 2) - (window.innerWidth <= 768?100:310);
            if(window.innerWidth > 768){
              this.backgroundPositionY2 = (window.scrollY / 2) - 800;
            }else{
              this.backgroundPositionY2 = 0
            }
            
        },
    }
}
</script>

<style lang="scss" scoped>
.detailBox{
  margin-top: 200px;
}
.detailBox_top {
  width: 100%;
  height: 687px;
  padding-left: 10%;
  background-image: url("../../assets/images/game33.png");
  background-position-x: center;
  background-position-y: -310px;
  background-size: 2000px;
}
.gameItem{
  width: 550px;
  height: 680px;
  position: relative;
  top: -20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 9px 9px 20px #d1cdd5;
  .gameItem_top{
      width: 70%;
      display: flex;
      justify-content: left;
      img{
          border-radius: 10px;
          margin-right: 40px;
      }
      .p1{font-size: 21px;}
      .p2{font-size: 16px;}
  }
  .gameItem_cen{
      width: 70%;
      margin: 40px auto;
      font-size: 35px;
  }
  .gameItem_bot{
    width: 70%;
    font-size: 18px;
  }
}

.detailBox_cen{
  width: 90%;
  margin: 50px auto;
  h2{
    font-size: 34px;
    margin: 50px 0;
  }
  .screenshotsBox{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    div{
      width: 45%;
      min-width: 380px;
      margin: 0 auto;
      height: 400px;
      border-radius: 20px;
      transition: background-size 1.5s ease;
    }
    .screenshots1{
      background-image: url("../../assets/images/gameDetail5.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 780px;
    }
    .screenshots2{
      background-image: url("../../assets/images/gameDetail6.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 780px;
    }
    div:hover{
      background-size:820px
    }
  }
}
.detailBox_down{
  margin-top: 50px;
  height: 735px;
  background-image: url("../../assets/images/game3.png");
  background-position-x: center;
  background-repeat: no-repeat;
  background-position-y: 0px;
  background-size: 2000px;
  position: relative;
  display: flex;
  align-items: center;
  .textBox{
    position: relative;
    z-index: 9;
    color: #fff;
    width: 40%;
    margin: 0 auto;
    text-align: center;
    .p1{font-size: 50px;}
    .p2{font-size: 18px;}
  }
  
}
.detailBox_down2{
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(255, 106, 106, 0.8),  rgba(255, 106, 106, 0.8) );
  background-blend-mode: lighten;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .gameItem{
    width: 90%;
    margin: 0 auto;
    top: 50%;
    height: 480px;
    .gameItem_cen{ font-size: 20px; }
    .gameItem_bot{ font-size: 16px; }
  }
  .detailBox{
    margin-top: 0;
    .detailBox_top{
      padding: 0;
      margin-bottom: 400px;
      width: 100%;
      height: 320px;
      background-size: 1000px;
      background-repeat: no-repeat;
    }
    .detailBox_cen{
      .screenshotsBox{
        div{
          width: 90%;
          height: 160px;
          margin-bottom: 30px;
          background-size: 400px;
        }
        div:hover{
          background-size: 430px;
        }
      }
    }
    .detailBox_down{
      height: 460px;
      background-size: 950px;
      .textBox{
        width: 90%;
        .p1{font-size: 26px;margin-bottom: 20px;}
        .p2{font-size: 16px;}
      }
    }
  }
  
}
</style>