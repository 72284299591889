<template>
    <div class="container">
        <HeaderPage></HeaderPage>
        <div class="caeersBox text_color">
            <div class="caeersBox_top">
                <p class="p1"><strong>Help Us Change the Way People Play</strong></p>
                <h1><strong>We’re Hiring</strong></h1>
                <p class="p2"><strong>We are a rapidly growing innovative technology company dedicated to providing exceptional solutions to our clients. Our team consists of passionate and creative professionals who are committed to pushing boundaries and striving for excellence together.</strong></p>
            </div>
        </div>
        <div class="contactBox">
            <div class="contactBox1">
                <h1 class="contactBox1Tit"><strong>Job Openings</strong></h1>
                <div class="contactBox1Con">
                    <div>
                        <img class="itemImg" src="../../assets/images/careers1.png" alt="">
                        <h1 class="itemTit"><strong>Software Engineer</strong></h1>
                        <p class="itemP1"><strong>Istanbul, Turkey</strong></p>
                        <p class="itemP2">
                            <strong>
                                Responsibilities: Design, develop, and maintain high-performance applications. <br>
                                Requirements: Proficient in at least one programming language, strong team collaboration skills, and the ability to solve complex problems.
                            </strong>
                        </p>
                        <el-button type="danger" style="background-color:#272243; border: none; border-radius: 5px; width: 190px;">Apply Now</el-button>
                    </div>
                    <div>
                        <img class="itemImg" src="../../assets/images/careers2.png" alt="">
                        <h1 class="itemTit"><strong>Product Manager</strong></h1>
                        <p class="itemP1"><strong>Istanbul, Turkey</strong></p>
                        <p class="itemP2">
                            <strong>
                                Responsibilities: Plan and manage product roadmaps, coordinate cross-department teams, and drive the entire product lifecycle from concept to market. <br>
                                Requirements: Excellent communication skills, strong market analysis capabilities, and relevant experience is preferred.
                            </strong>
                        </p>
                        <el-button type="danger" style="background-color:#272243; border: none; border-radius: 5px; width: 190px;">Apply Now</el-button>
                    </div>
                    <div>
                        <img class="itemImg" src="../../assets/images/careers3.png" alt="">
                        <h1 class="itemTit"><strong>Marketing Specialist</strong></h1>
                        <p class="itemP1"><strong>Istanbul, Turkey</strong></p>
                        <p class="itemP2">
                            <strong>
                                Responsibilities: Develop and implement marketing strategies to enhance brand awareness and market share. <br>
                                Requirements: Creative and planning skills, digital marketing experience is preferred.
                            </strong>
                        </p>
                        <el-button type="danger" style="background-color:#272243; border: none; border-radius: 5px; width: 190px;">Apply Now</el-button>
                    </div>
                    <div>
                        <img class="itemImg" src="../../assets/images/careers4.png" alt="">
                        <h1 class="itemTit"><strong>Data Analyst</strong></h1>
                        <p class="itemP1"><strong>Istanbul, Turkey</strong></p>
                        <p class="itemP2">
                            <strong>
                                Responsibilities: Collect, analyze, and interpret large datasets to inform business decisions and strategies. <br>
                                Requirements: Strong analytical skills, proficiency in data analysis tools and software, and the ability to communicate findings effectively.
                            </strong>
                        </p>
                        <el-button type="danger" style="background-color:#272243; border: none; border-radius: 5px; width: 190px;">Apply Now</el-button>
                    </div>
                </div>
            </div>
            <div class="contactBox2" :style="contactBackgroundStyle2">
                <p class="p1"><strong>Didn’t Find the Position You’re Looking For? </strong></p>
                <p class="p2"><strong>Send Us Your CV</strong></p>
                <el-button type="danger" style="color: #272243; background-color:#FCFF72; border: none; border-radius: 5px; width: 142px; height: 60px;">Apply Now</el-button>
            </div>
        </div>
        <FooterPage></FooterPage>
    </div>
</template>

<script>
import HeaderPage from "@/components/headerPage/index.vue"
import FooterPage from "@/components/footerPage/index.vue"
export default {
    name:"CaeersPage",
    components: {
        HeaderPage,
        FooterPage
    },
    data(){
        return{
            backgroundPositionY2:0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    computed:{
        contactBackgroundStyle2() {
            return {
                backgroundPositionY: this.backgroundPositionY2 + 'px',
            };
        },
    },
    methods:{
        handleScroll(){
            this.backgroundPositionY2 = (-1 * window.scrollY / 2.5) + (window.innerWidth <= 768?750:0);
        },
    }
}
</script>

<style lang="scss" scoped>
.container{
    background-color: #fff;
}
.caeersBox{
    width: 80%;
    margin: 0 auto;
    height: 450px;
    padding-top: 50px;
    .caeersBox_top{
        width: 40%;
        .p1{font-size: 21px;}
        h1{font-size: 50px;margin: 50px 0;}
        .p2{font-size: 18px;line-height: 28px;}
    }
}
.contactBox{
    width: 90%;
    margin: 0 auto;
    .contactBox1{
        width: 100%;
        background-color: #DA392B;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 50px 0;
        .contactBox1Tit{
            font-size: 50px;
            color: #fff;
            margin: 50px auto;
        }
        .contactBox1Con{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            div{
                width: 20%;
                min-width: 280px;
                color: #fff;
                margin: 30px auto;
                .itemImg{
                    max-width: 260px;
                    height: auto;
                    border-radius: 20px;
                }
                .itemTit{
                    width: 100%;
                    font-size: 21px;
                }
                p{font-size: 16px;}
                .itemP1{
                    margin: 15px 0;
                }
                .itemP2{
                    margin: 15px 0;
                    height: 30%;
                    line-height: 25px;
                }
            }
        }
    }
    .contactBox2{
        width: 100%;
        height: 600px;
        background-image: url("../../assets/images/contactBg2.png");
        background-repeat: no-repeat;
        background-position-y: 0;
        background-position-x: center;
        background-size: 2500px;
        display: flex;
        padding-left: 30px;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        .p1{font-size: 21px;}
        .p2{font-size: 50px;margin: 50px 0;}
    }
}
@media (max-width: 768px) {
    .caeersBox{
        .caeersBox_top{
            width: 80%;
            .p1{font-size: 18px;}
            h1{font-size: 30px;}
            .p2{font-size: 16px;}
        }
    }
    .contactBox{
        .contactBox1Con{
            div{
                .itemImg{
                    width: 275px !important;
                }
                .itemP2{
                    max-height: 170px !important;
                }
            }
        }
    }
}
</style>