<template>
  <div class="box">
    <HeaderPage></HeaderPage>
    <div class="content">
      <h1><strong>Privacy</strong></h1>
      <p>This Privacy Policy applies when you use our Services (described below). Please read it before using our Services because it will tell you how we collect, store, use, disclose, and otherwise process your personal information when providing these Services to you. This Privacy Policy is not, however, a contract and does not create any legal rights or obligations.</p>
      <p>If you have questions, comments, or concerns regarding our Privacy Policy or practices, please Contact Us.</p>
      <h2>Introduction</h2>
      <p>We offer a variety of online and mobile games. Our Privacy Policy applies whenever you use our “Services,” which means our games, products, services, content, and/or other domains or websites operated by us. It describes the ways we use your information and your rights and choices concerning your information.</p>
      <p>We develop and publish social games for web and mobile devices. “Subbi Games” “we,” “our,” or “us” in this Privacy Policy refers to Subbi Games and any other corporate entities under common ownership with Subbi Games.</p>
      <p>If you do not want We to collect, store, use, or share your information in the ways described in this Privacy Policy, you may not play our games or use our other Services.</p>
      <h2>Information We Collect and How We Collect It</h2>
      <p>We’ll collect and store any information that you provide to us, including when you create your account or a player profile.</p>
      <h2><strong>Information About You That You Share With Us Directly</strong></h2>
      <p>When you use our Services, you may give us information directly (like when you’re setting up your account), and we’ll store that information on our systems and use it for the purposes described in this Privacy Policy.</p>
      <p>Some games or parts of our Services may use a more traditional registration or account set-up process where you may be asked to give us information like:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your first and last name;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your username;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your gender;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your age or birthday;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your email address;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· a password; and/or</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· other information that helps us to identify you or helps us to provide or improve our services.</p>
      <p>We may also let you create a player profile that other players can see. Your player profile may include information like:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your profile photo;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your first and last name;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your game username;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your gender;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your biographical details (like your age or age range);</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· the approximate physical location information that you provide;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· links to your profiles on various social networks;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· details about the games you play; and/or</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· player ID that is used to identify your game account.</p>
      <p>If you give us your mobile telephone number (including for SMS text messaging services), we will also collect and store that number.</p>
      <h2>Information About Your Contacts</h2>
      <p>We’ll collect information that you provide to us about your contacts, but be sure to ask their permission first.</p>
      <p>We may give you the ability to import your address book contacts or to manually enter them so that you can locate your contacts using our Services and invite them to join you in our games or other aspects of our Services. You are responsible for ensuring your collection and sharing with us of your contacts’ information is compliant with applicable laws; this may require you to get your contacts’ permission before you give their contact details to us. We may also get information about you from other users importing or entering their contacts. We use and may store this contact information to help you and your contacts connect through our Services.</p>
      <h2>Information You Generate Using Our Services</h2>
      <p>We’ll collect information about the ways you use and interact with our Services.</p>
      <p>When you use our Services, we’ll collect information about the ways in which you use and interact with our Services, such as when and for how long you play our games, game features and other players you interact with, purchases you make, progress or levels you achieve, and other gameplay activities.</p>
      <p>If you choose to use communication features in our Services that allow you to communicate or share information with other players, then we’ll collect information on what is communicated or shared. This includes:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your participation in player forums and message boards;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your public posts or comments on other players’ profiles or game boards;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your nonpublic messages or invitations to other players, made either directly on our websites or through in-game mechanisms;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your chats with other players (whether by instant message, live chat, or video); and/or</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your posted photos, drawings, or other user-generated content.</p>
      <p>We may access in real-time, record, and/or store archives of these communications, comments, photos, drawings, and other user-generated content on our servers to make use of them to protect the safety and well-being of our players; to protect our rights and property in connection with our Services; to conduct research; to operate, improve, personalize, and optimize our Services and our players’ experiences, including through the use of analytics; and to manage and deliver advertising. Where required by law, we will seek your consent for this.</p>
      <h2>Customer Support Correspondence</h2>
      <p>We’ll keep a record of any correspondence between us.</p>
      <p>When you ask for help from our Customer Support team, we will collect and store the contact information you give them (generally, your name and email address), information about your game play or activity on our Services, and player and/or social network ID number. We will also store the communications you have with our Customer Service team and any additional information in those communications in order to provide support and improve the Services.</p>
      <h2>Information About You That We Get From Connected Third-Party Applications, Including Social Networks</h2>
      <p>We’ll collect some information from other companies, including social networks, if you access our games through your accounts with those companies or you choose to connect your accounts with those companies to our games.</p>
      <p>If you play our games or access any of our other Services on connected third-party applications or connect our Services to any third-party applications, including social networks like Facebook, We may receive certain information about you from the provider of the third-party application. The information we receive depends on the We game you’re playing, the third-party application, your privacy settings, and, if applicable, your friends’ privacy settings on that third-party application.</p>
      <p>For example, We may collect and store some or all of the following information from the provider of the connected third-party application:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your first and last name;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your profile picture or its URL;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your user identification number (like your Facebook ID number), which may be linked to publicly-available information like your name and profile photo;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your friends’ user ID numbers and other public data;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· the e-mail address you provided to that third-party application;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your approximate physical location and that of the devices you use to access our Services;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your gender;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your birthday, age, and/or age range;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· information about your activities on or through the connected third-party application;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· other publicly-available information on the third-party application; and/or</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· any other information that you or the provider of the third-party application share with us.</p>
      <p>If you access our Services from a third-party application or connect our Services to a third-party application, you should also read that third-party application’s terms of service and privacy policy.</p>
      <p>If you are unclear about what information a third-party application is sharing with us, please go to the third-party application to find out more about their privacy practices.</p>
      <h2>Cookies and Other Automated Information Collection</h2>
      <p>We’ll collect information about your device and how you use our Services, including through the use of cookies. You can find out how to control or opt-out of cookies in our Cookie Notice.</p>
      <p>As further described in our Cookie Notice, we use cookies and other similar technologies (e.g., beacons, pixel tags, clear gifs, and device identifiers) to recognize you and/or your device(s) on, off, and across different Services and devices. We also allow others to use cookies and similar technologies as described in our Cookie Notice. You can control or opt out of the use of cookies and similar technologies that track your behavior on the sites of others for third-party advertising, as described in our Cookie Notice.</p>
      <p>Subbi Games, our service providers, and our business partners use these cookies and other similar technologies to collect and analyze certain kinds of technical information, including:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· IP address;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· the type of computer or mobile device you are using;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· platform type (like Apple iOS or Android);</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your operating system version;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your mobile device’s identifiers, like your MAC Address, Apple Identifier For Advertising (IDFA), and/or Android Advertising ID (AAID);</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· application performance and debugging information;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your browser type and language;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· referring and exit pages, and URLs;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· the number of clicks on an app feature or web page;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· the amount of time spent on an app feature or web page;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· domain names;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· landing pages;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· pages viewed and the order of those pages; and/or</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· game state and the date and time of activity on our Services.</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· In some cases, we will connect the above information with your social network ID or user ID.</p>
      <p>If you play our games on a mobile device, in addition to your device identifiers (described above), we may also collect:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· the name you have associated with your device;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your country;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your telephone number (if you provide it);</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your specific geolocation (with your permission);</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· your mobile contacts (with your permission, as described further in Information About Your Contacts); and/or</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· information about our games and other third-party apps you have on your device.</p>
      <p>If you want to adjust your privacy preferences on your device, please see How to Access, Update, and Manage Your Personal Information.</p>
      <h2>Other Sources</h2>
      <p>We may collect information from other third-party sources. Where we do, we’ll let you know.</p>
      <p>We may collect or receive information about you from other sources like third-party information providers. We use this information along with information you provide us directly, for example, to help you and your friends connect or to serve you advertising more tailored to your interests.</p>
      <h2>How We Use Information We Collect and Our Legal Bases for Processing It</h2>
      <p>We use your information to provide, personalize, maintain the security of, and improve the Services you use; to maintain our business operations; to communicate with you; to protect you and others; to carry out marketing and advertising; and to exercise, defend or establish our rights.</p>
      <p>We use your information under several legal bases, including to establish and perform our contract with you, for our legitimate interests, to comply with our legal obligations, to establish, use and protect our rights, or otherwise with your consent.</p>
      <p>The main use of the information we collect and store is to provide you with the Services you request from us and to improve your gaming experience, but there are other uses as well. The uses for which we collect and store your information include:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to improve and optimize our Services and our players’ experiences;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to create your game accounts and allow you to play our games;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to identify and suggest connections with other players and personalize our Services to you;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to communicate with you about the Services you’re using, including in-game updates, new Services, and promotional offers that we think might be of interest to you;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to enable players to communicate with each other;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to provide technical support and respond to player inquiries;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to protect the safety and well-being of our players;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to protect our rights and property in connection with our Services;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to prevent fraud or potentially illegal activities, and to enforce our Terms of Service;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to manage and deliver contextual and behavioral advertising;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to administer rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by us or our business partners;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to comply with our legal obligations, resolve any disputes we may have with you or other players, and to enforce our agreements with third parties; and/or</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to conduct research.</p>
      <p>If you are located in the UK or European Countries (defined above in Section 1), our legal basis for collecting and using the information described above depends on what the information is and the context in which we collect it.</p>
      <p>However, we’ll normally collect information from you only where we need it to perform our contract with you (i.e., our Terms of Service), where the processing is in our legitimate interests (provided that these aren’t overridden by your interests or rights), or if we otherwise have your consent.</p>
      <p>In some cases, we may also have a legal obligation or need to collect information from you or may otherwise need the personal information to protect your vital interests or those of another person (for example, another player).</p>
      <p>If we ask you to provide information to comply with a legal requirement or to perform a contract with you, this will normally be clear from the context in which we are asking you to provide your information (for example, when we ask you to provide a username to access our Services, this will be to create an account for you in accordance with our Terms of Service), and, if not, we will provide an explanation to you at the relevant time. We will also make clear whether providing information is mandatory.</p>
      <p>Similarly, if we collect and use your information in reliance on legitimate interests (or those of any third party) which are not listed above, it will be made clear to you at the relevant time what those legitimate interests are (such as personalizing our services and marketing, for example).</p>
      <h2>How We Share Information</h2>
      <p>We’ll share your information with third parties when you use our Services under the circumstances described in this section.</p>
      <p>We’ll share some of your information with your friends and other We players that you’re playing with to facilitate your gaming interactions with them.</p>
      <p>We may share your information, including personal information, with third parties or allow third parties to collect this information from our Services in the following circumstances:</p>
      <p>Third-Party Advertising Including Behavioral Advertising and Analytics</p>
      <p>We’ll share some of your information with advertisers to tailor the advertisements you see when you use our Services to your interests and to enable us to offer some of our Services for free.</p>
      <p>We use advertising to enable us to offer some of our games, game features, and other Services for free. These advertisements may include, without limitation, banner ads on game boards or Services themselves, as full-screen ads between moves or games, or through an “offer wall” in which third-party advertisers offer to provide virtual currency or other in-game items to players in exchange for watching an advertisement or for completing a task, such as signing up for an account with one of the advertisers, completing a survey, or finishing a particular game level. Offer walls are hosted by third-party offer wall providers, and the offers are made by third-party advertisers, not by us.</p>
      <p>We do not actively share personal information that directly identifies you with third-party advertisers for their direct marketing purposes unless you give us your consent.</p>
      <p>To enable advertisers, ad networks, and offer wall providers to serve you with more relevant ads, they may collect, or we may collect and share with them, the following types of information when you use our Services:</p>
      <p>performance data (like the number of clicks on an advertisement);</p>
      <p>certain technical information (like IP address, non-persistent device identifier such as IDFA, and de-identified persistent device identifier such as a hashed Android ID);</p>
      <p>a unique identifier, such as your social network ID or player ID; and/or</p>
      <p>other contextual data about your game play (like your level and session length).</p>
      <p>In addition to serving you with relevant ads, the information collected may be used to:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· measure how effective ads are;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· offer you targeted advertising (both on and off our Services) in order to personalize your experience by showing you advertisements for products and services that are more likely to appeal to you (a practice known as interest-based advertising or behavioral advertising); and/or</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· undertake web analytics to analyze traffic and other player activity to improve your experience.</p>
      <p>Advertisers or ad networks may collect this information through the use of cookies and other similar tracking technologies, and they may use a single tracking technology or multiple tracking technologies at the same time.</p>
      <p>Please note, after clicking on a third-party advertisement or offer, you may no longer be on a site or app controlled by us or the social network through which you are playing our games.</p>
      <p>If you do not want to receive tailored in-application advertisements from third parties, please see How to Access, Update, and Manage Your Personal Information. For more information on controlling the use of cookies and similar technologies, please see our Cookie Notice. Please note that the practices of third-party companies delivering advertisements on our Services are subject to those companies’ own privacy policies.</p>
      <h2>Safety, Security, and Compliance with Law</h2>
      <p>We’ll share your information if necessary for safety, security, or compliance with law.</p>
      <p>Your information, and the contents of all of your online communications in our Services and between you and We may be accessed and monitored as needed to provide our Service and may be disclosed:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to satisfy any laws or regulations that apply;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· when we have a good faith belief that we have to disclose the information in response to lawful requests by public authorities, including for national security, law enforcement, or legal process purposes (for example, a court order, search warrant, or subpoena);</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· when we believe in our sole discretion that our Services are being used in committing a crime, including to report such criminal activity or to share information with other companies and organizations for the purposes of fraud protection, credit risk reduction, and other security precautions;</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· when we have a good faith belief that there is an emergency that poses a threat to the health and/or safety of you, another person, or the public generally; and/or</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;· to protect the rights or property of us or applicable third parties, including to enforce our Terms of Service.</p>
      <h2>Third-Party Service Providers</h2>
      <p>We’ll share your information with the third-party service providers and business partners that we work with to bring you our Services.</p>
      <p>We will share your information with third-party companies who perform services on our behalf, like payment processing, analytics, analysis, hosting services, customer service, marketing, and advertising optimization. We will also share your information with our third-party business partners who assist us in delivering and optimizing our Services, such as our platform partners (Apple, Google, Facebook, Amazon, etc.). Where we share your information with third-party service providers and business partners, we ensure that we have appropriate agreements in place to protect your information (as required by applicable data protection laws).</p>
      <h2>Children</h2>
      <p>We seek to ensure that we do not collect information from children.</p>
      <p>Generally speaking, most of our Services are not intended for children under the age of 13 (or under 16 for children located in the UK or European Countries) (collectively, “Children”). Children are not permitted to use these Services, and we do not knowingly collect any personal information from Children.</p>
      <p>Though our Services do not target Children as the primary audience, we may collect age information before allowing a user to proceed for certain Services. For users who identify themselves as Children in our age-gate on such Services, if we allow Children to use the Services, we will either provide a version of that Service that does not collect, use, or disclose “personal information” (as defined in the Children’s Online Privacy Protection Act (“COPPA”)), except as permitted by COPPA, or obtain legally valid parental consent.</p>
      <p>If we learn that we have inadvertently gathered personal information that is not subject to a COPPA exception from a Child, we will take reasonable measures to promptly remove that information from our records.</p>
      <h2>International Operations and Transfers Out of the UK or European Countries</h2>
      <p>When you use our Services, your personal information may be sent to the United States and possibly other countries. When we process personal information collected in the UK or European Countries outside of the UK or European Countries (as applicable), we comply with our legal obligations and ensure that your personal information is subject to suitable safeguards or transferred to “approved” territories.</p>
      <p>As part of our international operations, we may transfer personal information to our “Affiliated Entities,” which are any other corporate entities under common ownership with us, or to our business partners and service providers from time to time for our legitimate business purposes.</p>
      <p>We store personal information about players and visitors to our sites on servers located in the United States, and we may also process this information in other countries where our Affiliated Entities, business partners and service providers have their operations. Personal information collected in the UK or European Countries may be transferred to, and stored at, a destination outside of the UK or European Countries (as applicable) for purposes such as: (i) the processing of transactions and your payment details, or (ii) the provision of support services. When we transfer your personal information internationally we will take reasonable steps to ensure that your personal information is treated securely, lawfully, and in the manner we describe here. This may include implementing the European Commission’s Standard Contractual Clauses for transfers of personal information between our Affiliated Entities, business partners and service providers (where applicable) to protect personal information they process from the UK and the European Countries, or any other lawful solution that permits the lawful transfer of personal information from those countries. Please note that laws vary from jurisdiction to jurisdiction, so the privacy laws applicable to the places where your information is transferred to or stored, used, or processed in, may be different from the privacy laws applicable to the place where you are resident.</p>
      <p>In addition, Subbi Games and its US subsidiaries are Privacy Shield certified. The We entities and subsidiaries listed in our Privacy Shield certification record accessible here are certified under the EU-U.S. Privacy Shield, effective as of the date our certification posted here. Although we do not rely on the Privacy Shield to receive personal information from the UK and the European Companies under the General Data Protection Regulation (Regulation 2016/679), we will adhere to the relevant Privacy Shield Principles for all personal information received from the European Union and the UK.</p>
      <p>If your complaint still is not resolved through these channels, under limited circumstances more fully described on the Privacy Shield website, an additional binding arbitration option may be available to you.</p>
      <p>You may exercise any rights you have to correct, amend, or delete information about you as described in Your Rights and How to Access, Update, and Manage Your Personal Information.</p>
      <p>When We transfers personal information which is protected under the Privacy Shield to a third-party service provider acting as an agent on our behalf, We have certain liability under the Privacy Shield if both (i) the agent processes the information in a manner inconsistent with the Privacy Shield, and (ii) We are responsible for the event giving rise to the damage.</p>
      <p>With respect to personal information protected under or transferred pursuant to the Privacy Shield Framework, We are subject to the investigatory and enforcement powers of the Federal Trade Commission.</p>
      <h2>How Long We Keep Your Information</h2>
      <p>We’ll keep your information for as long as necessary to provide you with the Services, maintain our legitimate business operations, and/or exercise, defend or establish our rights.</p>
      <p>How long we retain your personal information depends on why we collected it and how we use it, but we will not retain your personal information for longer than is necessary for our business purposes or for legal requirements.</p>
      <p>We will retain personal information about you and connect with your account and/or the Services you use from us for as long as you have an active account with us. We will take reasonable measures to delete this personal information if you delete your account. However, you acknowledge that we may retain some information after you have closed, or we have deleted, your account with us where necessary to enable us to meet our legal obligations or to exercise, defend, or establish our rights.</p>
      <h2>Security of Your Information</h2>
      <p>We work hard to protect your information but you also have a role to play.</p>
      <p>We implement reasonable and appropriate security measures to help protect the security of your information both online and offline and to ensure that your data is treated securely and in accordance with this Privacy Policy. These measures vary based upon the sensitivity of your information.</p>
      <p>While we take precautions against possible security breaches of our Services and our customer databases and records, no website or Internet transmission is completely secure. We cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur, and we cannot guarantee the security of your information while it is being transmitted to our Service. Any transmission is at your own risk.</p>
      <h2>Changes to Our Privacy Policy</h2>
      <p>We’ll let you know about any material changes to the Privacy Policy.</p>
      <p>We may update our Privacy Policy from time to time. When we do, we will revise the “last updated” date at the top of the policy.</p>
      <h2>Contact Us</h2>
      <p>You can contact us with your questions, comments, or concerns.</p>
      <p>If you have any questions, comments, or concerns regarding our Privacy Policy and/or privacy practices, please send an e-mail to subbigames@gmail.com.</p>
    </div>
    <FooterPage></FooterPage>
  </div>
</template>

<script>
import HeaderPage from "@/components/headerPage/index.vue"
import FooterPage from "@/components/footerPage/index.vue"
export default {
  name: "DataprotectPage",
  components: {
    HeaderPage,
    FooterPage
  },
}
</script>

<style lang="scss" scoped>
.box{
  background-color: #fff;
  font-weight: bolder;
}
.content{
    width: 80%;
    margin: 150px auto;
    h1{
      margin-bottom: 40px;
    }
    h2{
      margin-bottom: 20px;
    }
    p{
      line-height: 25px;
      margin-bottom: 20px;
    }
}
</style>