var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('HeaderPage'),_c('div',{staticClass:"aboutBox text_color"},[_vm._m(0),_c('div',{staticClass:"aboutBox_topText"},[_c('div',{staticClass:"aboutBox_topText_l",style:(_vm.backgroundStyle)}),_vm._m(1)]),_c('div',{staticClass:"aboutBox_cen",style:(_vm.contactBackgroundStyle)},[_c('div',{staticClass:"aboutBox_cen_zzc"}),_vm._m(2)]),_vm._m(3),_c('div',{staticClass:"aboutBox_cen2",style:(_vm.contactBackgroundStyle2)},[_c('div',{staticClass:"aboutBox_cen2_zzc"}),_c('div',[_vm._m(4),_c('div',{staticClass:"aboutBox_cen2_r"},[_c('img',{attrs:{"src":require("../../assets/images/about9.png"),"alt":""}}),_c('div',[_vm._m(5),_vm._m(6),_c('el-button',{staticStyle:{"background-color":"#272243","border":"none","border-radius":"5px","width":"190px","margin":"30px 0"},attrs:{"type":"danger"}},[_vm._v("Explore Opportunities ")])],1)])])])]),_c('FooterPage')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutBox_top"},[_c('p',{staticClass:"p1"},[_c('strong',[_vm._v("We Are Tripo")])]),_c('h1',[_c('strong',[_vm._v("We are a creative and enterprising team")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutBox_topText_r"},[_c('p',[_vm._v("We are an ambitious company determined to enter the casual field and strive to become a leader in the field. We welcome people from all walks of life who are interested in casual games to join us and let's create the future of casual games together.")]),_c('p',[_vm._v("Our mission is to bring players endless fun and unforgettable experiences through high-quality game products. We believe that games are not only a form of entertainment, but also a way of connection and expression. Through our games, we hope to inspire players' imagination, bring joy and create wonderful memories.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"aboutBox_cenTit"},[_c('strong',[_vm._v("What We Are About")])]),_c('div',{staticClass:"aboutBox_cenBox"},[_c('div',{staticClass:"aboutBox_cenItem"},[_c('h2',[_vm._v("Accomplishment")]),_c('p',[_vm._v("In just a few short years since its founding, Subbi Games has achieved remarkable success, earning widespread recognition from the industry and gamers alike. Our game Galaxy Explorer already has over 100,000 users!")])]),_c('div',{staticClass:"aboutBox_cenItem"},[_c('h2',[_vm._v("Creativity")]),_c('p',[_vm._v("Our game Galaxy Explorer is the first in the industry to create a big world + Rougelike + Farming gameplay in an intergalactic setting!")])]),_c('div',{staticClass:"aboutBox_cenItem2"},[_c('h2',[_vm._v("Enterprising spirit")]),_c('p',[_vm._v("We always insist on innovation and quality, and provide players with the best experience by constantly optimizing and updating game content.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutBox_down"},[_c('p',{staticClass:"aboutBox_downTit"},[_c('strong',[_vm._v("Meet the Founders")])]),_c('div',{staticClass:"aboutBox_downBox"},[_c('div',{staticClass:"aboutBox_downItem"},[_c('img',{attrs:{"src":require("../../assets/images/about3.png"),"alt":""}}),_c('p',{staticClass:"p1"},[_c('strong',[_vm._v("Daisy Suarez")])]),_c('p',{staticClass:"p2"},[_c('strong',[_vm._v("CTO")])])]),_c('div',{staticClass:"aboutBox_downItem"},[_c('img',{attrs:{"src":require("../../assets/images/about4.png"),"alt":""}}),_c('p',{staticClass:"p1"},[_c('strong',[_vm._v("Ray Finigan")])]),_c('p',{staticClass:"p2"},[_c('strong',[_vm._v("Senior Game Developer")])])]),_c('div',{staticClass:"aboutBox_downItem"},[_c('img',{attrs:{"src":require("../../assets/images/about5.png"),"alt":""}}),_c('p',{staticClass:"p1"},[_c('strong',[_vm._v("Tao Deng")])]),_c('p',{staticClass:"p2"},[_c('strong',[_vm._v("Senior Game Designer")])])]),_c('div',{staticClass:"aboutBox_downItem"},[_c('img',{attrs:{"src":require("../../assets/images/about6.png"),"alt":""}}),_c('p',{staticClass:"p1"},[_c('strong',[_vm._v("Ben Singleton")])]),_c('p',{staticClass:"p2"},[_c('strong',[_vm._v("Senior Game Developer")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutBox_cen2_l",staticStyle:{"color":"#fff"}},[_c('p',{staticClass:"p1"},[_c('strong',[_vm._v("Our Offices")])]),_c('p',{staticClass:"p2"},[_c('strong',[_vm._v("Our Studio in"),_c('br'),_vm._v(" Turkey")])]),_c('p',{staticClass:"p3"},[_c('strong',[_vm._v("Located in the heart of Istanbul, Turkey, the company's state-of-the-art facilities and beautiful surroundings provide employees with a productive and comfortable workplace.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p1"},[_c('strong',[_vm._v("We’d Love to Work With You")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p2"},[_c('strong',[_vm._v("We are looking for talented people, as long as you excel in a certain area and meet our requirements, you have the opportunity to join our team!")])])
}]

export { render, staticRenderFns }