<template>
  <div class="container">
    <div :style="this.$route.name=='home'?'background-color: #82def7;':''">
      <div class="nav" >
        <div class="nav_left" @click="toPage('/home')">Subbi Games</div>

        <div class="nav_Right" v-show="!flag">
          <ul class="text_color">
            <li class="firstNav home"><a class="home" @click="toPage('/home')">Home</a></li>
            <li>
              <div class="subBox">
                <a class="games gameDetail1 gameDetail2 gameDetail3" style="width: 100%; height: 60px;">Games</a>
                <div class="subMenu">
                  <a @click="toPage('/gameDetail1')">Bubble Fever</a>
                  <a @click="toPage('/gameDetail2')">Robotrix</a>
                  <a @click="toPage('/gameDetail3')">Treasure Box</a>
                </div>
              </div>
            </li>
            <li><a class="careers" @click="toPage('/careers')">Careers</a></li>
            <li><a class="about" @click="toPage('/about')">About</a></li>
            <li class="lastNav contact"><a class="contact" @click="toScroll('contact')">Contact</a> </li>
          </ul>
        </div>
        <!-- 移动端显示内容 -->
        <el-button class="menuBtn el-icon-s-unfold" v-show="flag" @click="drawer = true" type="plian"> MENU </el-button>
        <el-drawer title="MENU" :visible.sync="drawer" :with-header="false" direction="ttb" size="50%">
          <ul class="header_right_menu" style="display: block; padding-left: 20px; list-style: none;">
            <li class="home" @click="toPage('/home')">Home</li>
            <li class="games" @click="openSubMenu()">
              <div class="subMenuBox">
                <p>Game</p>
                <div class="sunMenu">
                  <a @click="toPage('/gameDetail1')">Bubble Fever</a>
                  <a @click="toPage('/gameDetail2')">Robotrix</a>
                  <a @click="toPage('/gameDetail3')">Treasure Box</a>
                </div>
              </div>
            </li>
            <li class="careers" @click="toPage('/careers')">Careers</li>
            <li class="about" @click="toPage('/about')">About</li>
            <li class="contact" @click="toScroll('contact')">Contact</li>
            <li class="dataprotect" @click="toPage('/dataprotect.html')">Privacy Policy</li>
            <li class="regulation" @click="toPage('/regulation.html')">Terms Of Server</li>
          </ul>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderPage",
  data() {
    return {
      drawer: false,
      flag:false,
    };
  },
  mounted(){
    this.clickMenu();
    if (window.innerWidth < 768 || window.innerWidth < 768) {
      this.flag = true;
    }else{
      this.flag = false;
    }
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
    if (this.$route.name == "home") {
      document.getElementsByClassName("container")[0].style.backgroundColor = "#dae1e7";
    }
  },
  methods: {
    // 展开子菜单
    openSubMenu(){
      let subNode = document.getElementsByClassName("subMenuBox");
      if (subNode[0].style.height == "auto") {
        subNode[0].style.height = "60px";
      }else{
        subNode[0].style.height = "auto";
      }
    },
    clickMenu() {
      if (this.$route.name != "dataprotect" && this.$route.name != "regulation") {
        let className = this.$route.name;
        let Node = document.getElementsByClassName(className);
        Node[0].classList.add("active");
      }
    },
    toPage(key) {// 用于页面跳转
      this.$router.push({ 'path': key }).catch(() => { })
      this.drawer = false;
    },
    toScroll(id){
      if ( this.$route.name.includes('careers') || this.$route.name.includes('about')  || this.$route.name.includes('games')) {
        this.$router.push({ 'path': "/home" }).catch(() => { })
      }
      setTimeout(()=>{
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth'
            });
            this.drawer = false;
      }
      },1)
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #ffffff;
  width: 100%;
  line-height: 60px;
  .nav{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: static;
    top: 0;
    .nav_left{
      background-color: #DA392B;
      color: #fff;
      height: 100%;
      width: 166px;
      text-align: center;
      border-radius: 0 0 10px 10px;
      font-family: 'ALLURA';
      font-size: 24px;
    }
    .nav_Right{
      
      ul{
        display: flex;
        list-style: none;
        background-color: #fff;
        box-shadow: 9px 9px 5px #d1cdd5;
        border-radius: 0 0 10px 10px;
        .firstNav{
          border-radius: 0 0 0 10px;
        }
        .lastNav{
          border-radius: 0 0 10px 0;
        }
        li{
          width: 100px;
          text-align: center;
          height: 60px;
          a{
            display: block;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
          .subBox{
            height: 100%;
            overflow: hidden;
            transition: overflow .5s ease;
            .subMenu{
              background-color: #fff;
              border-radius: 10px;
              a{
                border-radius: 10px;
                transition: background-color .5s ease,color .5s ease;
              }
            }
          }
          .subBox:hover{
            overflow: visible;
            .subMenu{
              a:hover{
                background-color: #DA392B;
                color: #fff;
              }
            }
          }
        }
        .active{
          background-color: #DA392B;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header_right_menu{
    li{
      .subMenuBox{
        height: 60px;
        overflow: hidden;
        transition: height 0.5s ease;
        .sunMenu>a{display: block;}
      }
    }
  }
}
</style>