<template>
  <div class="container">
    <HeaderPage></HeaderPage>
    <div class="aboutBox text_color">
        <div class="aboutBox_top">
            <p class="p1"><strong>We Are Tripo</strong></p>
            <h1><strong>We are a creative and enterprising team</strong></h1>
        </div>
        <div class="aboutBox_topText">
            <div class="aboutBox_topText_l" :style="backgroundStyle"></div>
            <div class="aboutBox_topText_r">
                <p>We are an ambitious company determined to enter the casual field and strive to become a leader in the field. We welcome people from all walks of life who are interested in casual games to join us and let's create the future of casual games together.</p>
                <p>Our mission is to bring players endless fun and unforgettable experiences through high-quality game products. We believe that games are not only a form of entertainment, but also a way of connection and expression. Through our games, we hope to inspire players' imagination, bring joy and create wonderful memories.</p>
            </div>
        </div>
        <div class="aboutBox_cen" :style="contactBackgroundStyle">
            <div class="aboutBox_cen_zzc"></div>
            <div>
                <p class="aboutBox_cenTit"><strong>What We Are About</strong></p>
                <div class="aboutBox_cenBox">
                    <div class="aboutBox_cenItem">
                        <h2>Accomplishment</h2>
                        <p>In just a few short years since its founding, Subbi Games has achieved remarkable success, earning widespread recognition from the industry and gamers alike. Our game Galaxy Explorer already has over 100,000 users!</p>
                    </div>
                    <div class="aboutBox_cenItem">
                        <h2>Creativity</h2>
                        <p>Our game Galaxy Explorer is the first in the industry to create a big world + Rougelike + Farming gameplay in an intergalactic setting!</p>
                    </div>
                    <div class="aboutBox_cenItem2">
                        <h2>Enterprising spirit</h2>
                        <p>We always insist on innovation and quality, and provide players with the best experience by constantly optimizing and updating game content.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="aboutBox_down">
            <p class="aboutBox_downTit"><strong>Meet the Founders</strong></p>
            <div class="aboutBox_downBox">
                <div class="aboutBox_downItem">
                    <img src="../../assets/images/about3.png" alt="">
                    <p class="p1"><strong>Daisy Suarez</strong></p>
                    <p class="p2"><strong>CTO</strong></p>
                </div>
                <div class="aboutBox_downItem">
                    <img src="../../assets/images/about4.png" alt="">
                    <p class="p1"><strong>Ray Finigan</strong></p>
                    <p class="p2"><strong>Senior Game Developer</strong></p>
                </div>
                <div class="aboutBox_downItem">
                    <img src="../../assets/images/about5.png" alt="">
                    <p class="p1"><strong>Tao Deng</strong></p>
                    <p class="p2"><strong>Senior Game Designer</strong></p>
                </div>
                <div class="aboutBox_downItem">
                    <img src="../../assets/images/about6.png" alt="">
                    <p class="p1"><strong>Ben Singleton</strong></p>
                    <p class="p2"><strong>Senior Game Developer</strong></p>
                </div>
            </div>
        </div>
        <div class="aboutBox_cen2" :style="contactBackgroundStyle2">
            <div class="aboutBox_cen2_zzc"></div>
            <div>
                <div class="aboutBox_cen2_l" style="color: #fff;">
                    <p class="p1"><strong>Our Offices</strong></p>
                    <p class="p2"><strong>Our Studio in<br> Turkey</strong></p>
                    <p class="p3"><strong>Located in the heart of Istanbul, Turkey, the company's state-of-the-art facilities and beautiful surroundings provide employees with a productive and comfortable workplace.</strong></p>
                </div>
                <div class="aboutBox_cen2_r">
                    <img src="../../assets/images/about9.png" alt="">
                    <div>
                        <p class="p1"><strong>We’d Love to Work With You</strong></p>
                        <p class="p2"><strong>We are looking for talented people, as long as you excel in a certain area and meet our requirements, you have the opportunity to join our team!</strong></p>
                        <el-button type="danger" style="background-color:#272243; border: none; border-radius: 5px; width: 190px; margin: 30px 0;">Explore Opportunities </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterPage></FooterPage>
  </div>
</template>

<script>
import HeaderPage from "@/components/headerPage/index.vue"
import FooterPage from "@/components/footerPage/index.vue"
export default {
    name:"AboutPage",
    components: {
        HeaderPage,
        FooterPage
    },
    data(){
        return{
            backgroundPositionX: -1150,
            backgroundPositionY:0,
            backgroundPositionY2:570,
        }
    },
    computed:{
        backgroundStyle() {
            return {
                backgroundPositionX: this.backgroundPositionX + 'px',
            };
        },
        contactBackgroundStyle() {
            return {
                backgroundPositionY: this.backgroundPositionY + 'px',
            };
        },
        contactBackgroundStyle2() {
            return {
                backgroundPositionY: this.backgroundPositionY2 + 'px',
            };
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        if (window.innerWidth < 768) {
            this.backgroundPositionX = 0;
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods:{
        handleScroll(){
            this.backgroundPositionX =  (-1 * window.scrollY / 3) -(window.innerWidth >= 768?1150:0); // 根据滚动距离调整，这里是3倍的滚动速度
            this.backgroundPositionY = (-1 * window.scrollY / 2.5) + (window.innerWidth <= 768?550:0);
            this.backgroundPositionY2 = (-1 * window.scrollY / 2.5) + (window.innerWidth <= 768?1300:570);
        },
    }
}
</script>

<style lang="scss" scoped>
.container{
    background-color: #fff;
}
.aboutBox{
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;
    .aboutBox_top{
        width: 80%;
        margin: 0 auto;
        .p1{font-size: 21px;}
        h1{font-size: 50px;margin: 30px 0;}
    }
}
.aboutBox_topText{
    width: 100%;
    height: 600px;
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .aboutBox_topText_l{
        height: 100%;
        width: 40%;
        min-width: 320px;
        background-Image: url("@/assets/images/about1.png");
        background-position: center;
        background-position-x: -1150px;
        background-repeat: no-repeat;
    }
    .aboutBox_topText_r{
        height: 100%;
        width: 60%;
        padding: 40px 15%;
        background-color: #DA392B;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
}
.aboutBox_cen{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 860px;
    background-image: url("../../assets/images/about2.png");
    background-blend-mode: lighten;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: 2000px;
    position: relative;
    .aboutBox_cen_zzc{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(rgba(201, 201, 201, 0.8),  rgba(201, 201, 201, 0.8) );
        background-blend-mode: lighten;
    }
    div{
        position: relative;
        z-index: 9;
        .aboutBox_cenTit{
            font-size: 50px;
            text-align: center;
            color: #272443;
        }
        .aboutBox_cenBox{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            width: 70%;
            margin: 30px auto;
            div{
                border-top: 15px;
                box-shadow: 9px 9px 5px #d1cdd5;
                background-color: #fff;
                border-radius: 20px;
                width: 390px;
                height: 240px;
                padding: 30px 20px;
                margin: 0 5px 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }
            .aboutBox_cenItem2{
                width: 60%;
            }
        }
    }
}
.aboutBox_down{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 80%;
    height: 700px;
    margin: 0 auto 50px;
    background-color: #272443;
    .aboutBox_downTit{
        color: #fff;
        font-size: 50px;
        text-align: center;
    }
    .aboutBox_downBox{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .aboutBox_downItem{
            text-align: center;
            color: #fff;
            margin: 30px 30px;
            img{
                width: 259px;
                height: auto;
                border-radius: 20px;
            }
            .p1{font-size: 21px;}
            .p2{font-size: 16px;}
        }
        
    }
}
.aboutBox_cen2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 860px;
    background-image: url("../../assets/images/about7.png");
    background-repeat: no-repeat;
    background-position-x: center;
    align-items: center;
    background-size: 3400px;
    position: relative;
    .aboutBox_cen2_zzc{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(rgba(255, 106, 106, 0.8),  rgba(255, 106, 106, 0.8) );
        background-blend-mode: lighten;
    }
    div{
        position: relative;
        z-index: 9;
        display: flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        div{ width: 50%;}
        .aboutBox_cen2_l{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 40%;
            .p1{font-size: 21px;}
            .p2{font-size: 50px;margin: 50px 0;}
            .p3{font-size: 16px;}
        }
        .aboutBox_cen2_r{
            width: 390px;
            height: 430px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 20px;
            .p1{font-size: 21px;}
            .p2{font-size: 16px;}
            img{
                position: relative;
                top: -50px;
            }
        }
    }
}
@media (max-width: 768px) {
    .aboutBox{
        .aboutBox_top{
            width: 80%;
            .p1{font-size: 18px;}
            h1{font-size: 30px;}
        }
    }
    .aboutBox_topText{
        width: 100%;
        height: auto;
        .aboutBox_topText_l{
            width: 100%;
            height: 250px;
            background-position-x:center !important;
            background-size: cover !important;
        }
        .aboutBox_topText_r{
            width: 100%;
            p{
                margin-bottom: 30px;
            }
        }
        
    }
    .aboutBox_cen{
        height: 950px;
        div{
            .aboutBox_cenBox{
            .aboutBox_cenItem2{
                width: 390px;
            }
        }
        }
    }
    .aboutBox_down{
        height: auto;
    }
    .aboutBox_cen2{
        div{
            .aboutBox_cen2_l{
                width: 90%;
                .p1{font-size: 18px;}
                .p2{font-size: 26px; margin: 20px 0;}
                .p3{font-size: 16px; margin-bottom: 60px;}
            }
        }
    }
}
</style>