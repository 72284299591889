//引入vue-router路由插件
import VueRouter from "vue-router";
//引入Vue
import Vue from "vue";
Vue.use(VueRouter);

//引入路由组件
import HomePage from '@/view/homePage/index'
import CareersPage from "@/view/careersPage/index"
import AboutPage from "@/view/aboutPage/index"
import GameDetail1 from "@/view/gamePage/gameDetail1.vue";
import GameDetail2 from "@/view/gamePage/gameDetail2.vue";
import GameDetail3 from "@/view/gamePage/gameDetail3.vue";
import Dataprotect from "@/view/privacyPage/index.vue"
import Regulation from "@/view/termsPage/index.vue"
const router = new VueRouter({
    mode: 'history',
    //配置路由
    routes:[
        {
            name:'home',
            path: '/home',
            component: HomePage,
        },{
            name:'careers',
            path: '/careers',
            component: CareersPage, 
        },{
            name:'gameDetail1',
            path: '/gameDetail1',
            component: GameDetail1, 
        },{
            name:'gameDetail2',
            path: '/gameDetail2',
            component: GameDetail2, 
        },{
            name:'gameDetail3',
            path: '/gameDetail3',
            component: GameDetail3, 
        },{
            name:'about',
            path: '/about',
            component: AboutPage, 
        },{
            name:'dataprotect',
            path: '/dataprotect.html',
            component: Dataprotect, 
        },{
            name:'regulation',
            path: '/regulation.html',
            component: Regulation, 
        },{
            path: '*',
            redirect: '/home',
        }
    ]
  })


export default router
