<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app{
  background-color: #d5dbe2;
  width: 100%;
  height: 100%;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'GlowSansSC';
}
a{
  text-decoration: none;
}
.text_color{
  color: #272443;
}
.c3{
  color: #333;
}
.c6{
  color: #666;
}
.c9{
  color: #999;
}

</style>
