var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('HeaderPage'),_vm._m(0),_c('div',{staticClass:"contactBox"},[_c('div',{staticClass:"contactBox1"},[_vm._m(1),_c('div',{staticClass:"contactBox1Con"},[_c('div',[_c('img',{staticClass:"itemImg",attrs:{"src":require("../../assets/images/careers1.png"),"alt":""}}),_vm._m(2),_vm._m(3),_vm._m(4),_c('el-button',{staticStyle:{"background-color":"#272243","border":"none","border-radius":"5px","width":"190px"},attrs:{"type":"danger"}},[_vm._v("Apply Now")])],1),_c('div',[_c('img',{staticClass:"itemImg",attrs:{"src":require("../../assets/images/careers2.png"),"alt":""}}),_vm._m(5),_vm._m(6),_vm._m(7),_c('el-button',{staticStyle:{"background-color":"#272243","border":"none","border-radius":"5px","width":"190px"},attrs:{"type":"danger"}},[_vm._v("Apply Now")])],1),_c('div',[_c('img',{staticClass:"itemImg",attrs:{"src":require("../../assets/images/careers3.png"),"alt":""}}),_vm._m(8),_vm._m(9),_vm._m(10),_c('el-button',{staticStyle:{"background-color":"#272243","border":"none","border-radius":"5px","width":"190px"},attrs:{"type":"danger"}},[_vm._v("Apply Now")])],1),_c('div',[_c('img',{staticClass:"itemImg",attrs:{"src":require("../../assets/images/careers4.png"),"alt":""}}),_vm._m(11),_vm._m(12),_vm._m(13),_c('el-button',{staticStyle:{"background-color":"#272243","border":"none","border-radius":"5px","width":"190px"},attrs:{"type":"danger"}},[_vm._v("Apply Now")])],1)])]),_c('div',{staticClass:"contactBox2",style:(_vm.contactBackgroundStyle2)},[_vm._m(14),_vm._m(15),_c('el-button',{staticStyle:{"color":"#272243","background-color":"#FCFF72","border":"none","border-radius":"5px","width":"142px","height":"60px"},attrs:{"type":"danger"}},[_vm._v("Apply Now")])],1)]),_c('FooterPage')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"caeersBox text_color"},[_c('div',{staticClass:"caeersBox_top"},[_c('p',{staticClass:"p1"},[_c('strong',[_vm._v("Help Us Change the Way People Play")])]),_c('h1',[_c('strong',[_vm._v("We’re Hiring")])]),_c('p',{staticClass:"p2"},[_c('strong',[_vm._v("We are a rapidly growing innovative technology company dedicated to providing exceptional solutions to our clients. Our team consists of passionate and creative professionals who are committed to pushing boundaries and striving for excellence together.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"contactBox1Tit"},[_c('strong',[_vm._v("Job Openings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"itemTit"},[_c('strong',[_vm._v("Software Engineer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"itemP1"},[_c('strong',[_vm._v("Istanbul, Turkey")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"itemP2"},[_c('strong',[_vm._v(" Responsibilities: Design, develop, and maintain high-performance applications. "),_c('br'),_vm._v(" Requirements: Proficient in at least one programming language, strong team collaboration skills, and the ability to solve complex problems. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"itemTit"},[_c('strong',[_vm._v("Product Manager")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"itemP1"},[_c('strong',[_vm._v("Istanbul, Turkey")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"itemP2"},[_c('strong',[_vm._v(" Responsibilities: Plan and manage product roadmaps, coordinate cross-department teams, and drive the entire product lifecycle from concept to market. "),_c('br'),_vm._v(" Requirements: Excellent communication skills, strong market analysis capabilities, and relevant experience is preferred. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"itemTit"},[_c('strong',[_vm._v("Marketing Specialist")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"itemP1"},[_c('strong',[_vm._v("Istanbul, Turkey")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"itemP2"},[_c('strong',[_vm._v(" Responsibilities: Develop and implement marketing strategies to enhance brand awareness and market share. "),_c('br'),_vm._v(" Requirements: Creative and planning skills, digital marketing experience is preferred. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"itemTit"},[_c('strong',[_vm._v("Data Analyst")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"itemP1"},[_c('strong',[_vm._v("Istanbul, Turkey")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"itemP2"},[_c('strong',[_vm._v(" Responsibilities: Collect, analyze, and interpret large datasets to inform business decisions and strategies. "),_c('br'),_vm._v(" Requirements: Strong analytical skills, proficiency in data analysis tools and software, and the ability to communicate findings effectively. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p1"},[_c('strong',[_vm._v("Didn’t Find the Position You’re Looking For? ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p2"},[_c('strong',[_vm._v("Send Us Your CV")])])
}]

export { render, staticRenderFns }