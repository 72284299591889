<template>
  <div class="container">
    <div class="footer_top" @click="toPage('/home')">Subbi Games</div>
    <p class="footer_top_text"> An Immersive Gaming Experience </p>
    <div class="hr"></div>
    <div class="menuBox">
      <div class="menuItem">
        <h2>Contact</h2>
        <ul>
          <li><strong>subbigames@gmail.com</strong></li>
        </ul>
      </div>
      <div class="menuItem">
        <h2>Navigation</h2>
        <ul>
          <li><a class="" @click="toScroll('game')"><strong>games</strong></a></li>
          <li><a class="" @click="toPage('/careers')"><strong>Careers</strong></a></li>
          <li><a class="" @click="toPage('/about')"><strong>About</strong></a></li>
          <li><a class="" @click="toScroll('contact')"><strong>Contact</strong></a></li>
          <li><a class="" @click="toPage('/dataprotect.html')"><strong>Privacy Policy</strong></a></li>
          <li><a class="" @click="toPage('/regulation.html')"><strong>Terms & Conditions</strong></a></li>
        </ul>
      </div>
      <div class="menuItem">
        <h2>Social</h2>
        <ul>
          <li><strong>Discord</strong></li>
          <li><strong>Twitch</strong></li>
          <li><strong>Facebook</strong></li>
          <li><strong>YouTube</strong></li>
          <li><strong>Twitter</strong></li>
          <li><strong>LinkedIn</strong></li>
        </ul>
      </div>
      <div class="menuItem">
        <h2>Find Us On</h2>
        <ul>
          <li><img src="../../assets/images/Appstore.png" alt=""></li>
          <li><img src="../../assets/images/Google Play.png" alt=""></li>
        </ul>
      </div>
    </div>
    <div class="hr"></div>
    <p class="footer_bot"><strong>© 2024 by Subbi Games.</strong></p>
  </div>
</template>

<script>
export default {
    name:"FooterPage",
    data(){
      return{
       
      }
    },
  mounted(){
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
  },
  methods:{
    toScroll(id){
      if ( this.$route.name.includes('careers') || this.$route.name.includes('about')  || this.$route.name.includes('games')) {
        this.$router.push({ 'path': "/home" }).catch(() => { })
      }
      setTimeout(()=>{
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth'
            });
            this.drawer = false;
      }
      },1)
    },
    toPage(key) {// 用于页面跳转
      this.$router.push({ 'path': key }).catch(() => { })
    },
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  background-color: #fff;
  .footer_top{
    background-color: #DA392B;
    color: #fff;
    line-height: 60px;
    width: 166px;
    margin: 0 auto;
    text-align: center;
    border-radius: 0 0 10px 10px;
    font-family: 'ALLURA';
    font-size: 24px;
  }
  .footer_top_text{
    text-align: center;
    font-size: 24px;
    color: #272443;
    margin: 30px auto;
  }
  .hr{
    width: 80%;
    margin: 0 auto;
    border: 1px solid #272443;
  }
  .menuBox{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 50px;
    .menuItem{
      h2{font-size: 21px;color: #272443;}
      ul{
        list-style: none;
        margin: 30px 0;
        li{
          font-size: 16px;
          color: #757575;
          line-height: 32px;
          a{cursor: pointer;}
          img{width: auto; height: 40px;}
        }
      }
    }
  }
  .footer_bot{
    padding: 50px;
    text-align: center;
    color: #757575;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
 .container{
  
 }
}
</style>